import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    IconButton,
    Box,
    Button,
    Typography,
    Checkbox,
    InputAdornment,
    Grid,
    TextField,
    MenuItem,
    Link,
    FormControl,
    Select,
    Snackbar,
    Modal,
    Fade,
    styled,
    InputLabel,
    Popover,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { boyImg, girlImg, personLogo, signUpbackgroundTwo } from './assets';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './signup.css';
import { Alert as MuiAlert } from '@material-ui/lab';
import { termsRegex } from "../../../components/src/assets";

import SignupAccountController, {
    Props,
} from "./SignupAccountController";
import Header from "../../emailnotifications2/src/TopHeader.web";
import { configJSON } from "./EmailAccountController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class SignupBlock extends SignupAccountController {
    constructor(props: Props) {
        super(props);
    }

    countryCodeDropdown() {
        return (
            <InputAdornment style={{
                paddingLeft: "-8px",
                marginRight: "0px"
            }} position="start">
                <Box
                    style={{
                        paddingLeft: "-8px",
                        color: "#2966FF",
                        marginRight: "0px"
                    }}
                >
                    <div onClick={(e) => this.setState({ anchorElePopOver: e.currentTarget, openPopOver: true })} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                        <p>{this.state.selectedCountryCode} </p>
                        <KeyboardArrowDownIcon style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: "13px" }} />
                    </div>

                    <Popover
                        open={this.state.openPopOver}
                        anchorEl={this.state.anchorElePopOver}
                        onClose={() => this.setState({ anchorElePopOver: null, openPopOver: false })}
                        style={{
                            maxHeight: "250px",
                            maxWidth: "100px"
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        {this.state.countryCodesData.map((item: any) => {
                            return (
                                <MenuItem onClick={(e) => this.handleCountryChange(e)} value={item.attributes.country_code} style={{ width: "80px", padding: "5px", cursor: "pointer" }} key={item.id}>
                                    +{item.attributes.country_code}
                                </MenuItem>
                            )
                        })}

                    </Popover>
                </Box>
            </InputAdornment>
        )
    }
    renderCountryTextField = (params: any) => {
        return (<TextField {...params} variant="outlined" style={{padding:0}} placeholder="Please Select country"/>)
    }
    renderCityTextField = (params: any) => {
        return (<TextField {...params} variant="outlined" style={{padding:0}} placeholder="Please Select City"/>)
    }

    displayTermsData({ data } : any) {
        // Split the data into paragraphs
        const paragraphs = data.split(termsRegex).filter((paragraph: any) => paragraph.trim() !== '');
      
        return (
          <div style={{ maxHeight: "170px", overflowY: "scroll"}}>
            {paragraphs.map((paragraph: any, index: any) => (
              <Typography style={{ marginBottom: 20, fontSize: '12px',textAlign: "justify" }} key={index}>
                {paragraph.trim()}
              </Typography>
            ))}
          </div>
        );
      }

    render() {
        return (
            <>
                <Header />
                <Snackbar open ={this.state.snackbarOpen} autoHideDuration={2000} style={{ top: "81px" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={this.handleClose}>
                    <MuiAlert icon={this.handleIconError()} style={{ backgroundColor: "white", borderRadius: "8px", display: "flex", alignItems: "center"}}>
                        <Typography>{this.state.signupError || this.state.signupSuccessMessage}</Typography>
                    </MuiAlert>
                </Snackbar>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={this.state.modalOpen}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div
                            className="paper"
                        >
                            <Typography style={{ fontSize: '18px', fontWeight: 'bold', color: '#2966ff', textAlign: 'center', marginBottom: 30 }}>Terms and Conditions</Typography>
                            <Box>                                    
                                {this.displayTermsData({data :this.state.termsAndConditionData})}
                            </Box>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: 20,
                            }}>
                                <Box
                                    sx={{
                                        fontSize: "13px",
                                        fontWeight: "bold",
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Checkbox
                                        data-test-id={"termsAndCondition"}
                                        color="primary"
                                        onClick={() =>
                                            this.setState({ termsAndCondition: !this.state.termsAndCondition })
                                        }
                                        style={{
                                            padding: 0
                                        }}
                                        checked={this.state.termsAndCondition}
                                    />{" "}
                                    <Typography
                                        style={{
                                            fontSize: '13px',
                                            cursor: 'pointer',
                                            marginLeft: '15px'
                                        }}
                                    >I have read and agree to these Terms and Conditions.</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                <Button onClick={() => {
                                    this.handleModalClose();

                                }} data-test-id={"terms-and-condition-cancel-btn"} style={{ textTransform: 'capitalize', borderColor: '#2967ff', padding: '10px 30px', marginRight: '15px' }} variant="outlined" color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={!this.state.termsAndCondition} onClick={() => {
                                    this.handleModalClose();
                                    this.setRememberMe(!this.state.checkedRememberMe)
                                }} data-test-id={"terms-and-condition-agree-btn"} style={{ textTransform: 'capitalize', backgroundColor: '#2967ff', padding: '10px 30px', color: "white" }} variant="contained" color="primary">
                                    Agree
                                </Button>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
                <ThemeProvider theme={theme}>
                    <SignUpPageBox>
                        <Grid
                            container
                            justifyContent="center"
                            style={{ margin: '40px 0' }}
                        >
                            <Grid item md={4}
                                style={{
                                    border: '0.5px solid #e7eff7',
                                    boxShadow: '0 10px 20px 0 rgb(216, 255, 255)',
                                    borderRadius: '10px',
                                    backgroundColor: '#ffffff'
                                }}
                            >
                                <CustomTab>
                                    {
                                        this.state.switchTabDataTwo.map((tabName: string, index: number) => {
                                            return (
                                                <>
                                                    {
                                                        this.state.activeSwitchTabTwo === tabName ?
                                                            <ActiveButton
                                                                data-test-id="tabButtonId"
                                                                key={index} onClick={() => this.switchTab(tabName)}>{tabName}</ActiveButton>
                                                            :
                                                            <UnActiveButton data-test-id="unactivetabId" key={index} onClick={() => this.switchTab(tabName)}>{tabName}</UnActiveButton>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </CustomTab>
                                {
                                    this.state.activeSwitchTabTwo === "Customer" &&
                                    <Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.firstName}</SignInInputLable>
                                            <TextField
                                                style={{ marginTop: '4px', width: '100%' }}
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                value={this.state.firstName}
                                                data-test-id="txtInputFirsName"
                                                onChange={this.handleFirstNameChange}
                                                placeholder="Enter your first name"

                                            />
                                        </Box>

                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.lastname}</SignInInputLable>
                                            <TextField
                                                id="outlined-start-adornment"
                                                data-test-id="txtInputLastName"
                                                variant="outlined"
                                                style={{ marginTop: '4px', width: '100%' }}
                                                placeholder="Enter your last name"
                                                value={this.state.lastName}
                                                onChange={this.handleLastNameChange}
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.phoneNum}</SignInInputLable>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <TextField
                                                    name='phone'
                                                    id="outlined-start-adornment"
                                                    data-test-id="txtInputLastName"
                                                    variant="outlined"
                                                    value={this.state.phoneNumber}
                                                    placeholder="8488xxxxxx"
                                                    style={{ marginTop: '4px', width: '100%' }}
                                                    error={!!this.state.errorObj.phoneNumberError}
                                                    helperText={this.state.errorObj.phoneNumberError}
                                                    onChange={this.handlePhoneNumberChange}
                                                    InputProps={{ startAdornment: this.countryCodeDropdown()}}
                                                />
                                            </Box>
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.email}</SignInInputLable>
                                            <TextField
                                                placeholder="xyz@gmail.com"
                                                data-test-id="txtInputEmail"
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                error={!!this.state.errorObj.emailError}
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                style={{ marginTop: '4px', width: '100%' }}
                                                helperText={this.state.errorObj.emailError}
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="outlined-adornment-password">Password</SignInInputLable>
                                            <TextField
                                                id="outlined-adornment-password"
                                                data-test-id="txtInputPassword"
                                                style={{ marginTop: '4px', width: '100%' }}
                                                variant="outlined"
                                                type={this.state.enablePasswordField ? 'text' : 'password'}
                                                onChange={this.handlePasswordChange}
                                                value={this.state.password}

                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.handleEnablePassword()}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }}
                                                placeholder="*********"
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="outlined-adornment-password">{configJSON.confirm}</SignInInputLable>
                                            <TextField
                                                data-test-id="txtInputConfirmPassword"
                                                value={this.state.confirmPassword}
                                                variant="outlined"
                                                id="outlined-adornment-password"
                                                helperText={this.state.errorObj.confirmPasswordError}
                                                onChange={this.handleConfirmPassword}
                                                style={{ marginTop: '4px', width: '100%' }}
                                                type={this.state.enableConfirmPasswordField ? 'text' : 'password'}
                                                error={!!this.state.errorObj.confirmPasswordError}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickConfirmShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.handleEnableConfirmPassword()}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }}
                                                placeholder="*********"
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="Country-select-field" >Country</SignInInputLable>
                                            <Autocomplete
                                                id="Country-select-field"
                                                className="country-select-container"
                                                style={{ marginTop: '4px', width: '100%', height: 46 }}
                                                options={this.state.countryData}
                                                getOptionLabel={(option: any) => option.name}                                                     
                                                data-testid="country"
                                                value={this.state.selectedCountry}
                                                onChange={this.onCountryChange}
                                                renderInput={this.renderCountryTextField}
                                                disableClearable={this.state.selectedCountry? false: true}
                                            />
                                            
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="city-select-field" >City</SignInInputLable>
                                            <Autocomplete
                                                id="city-select-field"
                                                className="country-select-container"
                                                style={{ marginTop: '4px', width: '100%', height: 46 }}
                                                options={this.state.cityData}
                                                getOptionLabel={(option: any) => option.name}                                                     
                                                data-testid="country"
                                                value={this.state.selectedcity}
                                                onChange={this.onCityChange}
                                                renderInput={this.renderCityTextField}
                                                disableClearable={this.state.selectedcity? false: true}
                                            />
                                        </Box>

                                        <Box style={{

                                            marginLeft: '50px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginRight: '50px',
                                            alignItems: 'center',
                                            marginTop: 20,
                                        }}>
                                            <Box

                                                sx={{
                                                    fontSize: "13px",
                                                    alignItems: 'center',
                                                    fontWeight: "bold",
                                                    display: 'flex',
                                                }}
                                            >

                                                <Checkbox
                                                    data-test-id={"btnRememberMe"}
                                                    color="primary"
                                                    onClick={this.handleTermsAndCondition}

                                                    style={{
                                                        padding: 0
                                                    }}
                                                    checked={this.state.checkedRememberMe}
                                                />{" "}

                                                <Typography
                                                    onClick={this.handleTermsAndCondition}
                                                    style={{
                                                        fontSize: '13px',
                                                        cursor: 'pointer',
                                                        marginLeft: '8px',
                                                        color: '#2967ff',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#2967ff',
                                                    }}
                                                >{configJSON.accept}</Typography>
                                            </Box>
                                        </Box>
                                        <SignInButtonBox style={{ margin: '10px 50px', marginBottom: "0px" }}>
                                            <SignInButton disabled={!this.state.isAllFieldsFilled} onClick={this.newUserSignup} data-test-id={"btnSignup"} style={{ width: '100%', backgroundColor: '#2967ff' }} variant="contained" color="primary">
                                                Sign up
                                            </SignInButton>
                                        </SignInButtonBox>
                                        <Box style={{ textAlign: 'center', marginTop: "18px", marginBottom: '23px' }}>
                                            <Typography style={{ fontSize: '16px', fontFamily: "Lato" }}>Already have an account?<LoginText underline="none" data-test-id="custLogin" onClick={() => this.handleLogInNaviigation()} style={{ cursor: "pointer", fontSize: '15px', fontWeight: 'bold', fontFamily: "Lato" , marginLeft:"2px"}}>{` Sign in`}</LoginText></Typography>
                                        </Box>
                                    </Box>
                                }
                                {
                                    this.state.activeSwitchTabTwo === "Contractor" &&
                                    <Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.firstName}</SignInInputLable>
                                            <TextField
                                                style={{ marginTop: '4px', width: '100%' }}
                                                data-test-id="txtInputFirsName"
                                                onChange={this.handleFirstNameChange}
                                                variant="outlined"
                                                value={this.state.firstName}
                                                id="outlined-start-adornment"
                                                placeholder="Enter your first name"

                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.lastname}</SignInInputLable>
                                            <TextField
                                                onChange={this.handleLastNameChange}
                                                data-test-id="txtInputLastName"
                                                value={this.state.lastName}
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                style={{ marginTop: '4px', width: '100%' }}
                                                placeholder="Enter your last name"

                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.phoneNum}</SignInInputLable>
                                            <TextField
                                                style={{ marginTop: '4px', width: '100%' }}
                                                data-test-id="txtInputLastName"
                                                helperText={this.state.errorObj.phoneNumberError}
                                                error={!!this.state.errorObj.phoneNumberError}
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                placeholder="8488xxxxxx"
                                                onChange={this.handlePhoneNumberChange}
                                                name='phone'
                                                value={this.state.phoneNumber}
                                                InputProps={{ startAdornment: this.countryCodeDropdown()}}
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable>{configJSON.email}</SignInInputLable>
                                            <TextField
                                                helperText={this.state.errorObj.emailError}
                                                data-test-id="txtInputEmail1"
                                                variant="outlined"
                                                id="outlined-start-adornment"
                                                style={{ marginTop: '4px', width: '100%' }}
                                                error={!!this.state.errorObj.emailError}
                                                placeholder="xyz@gmail.com"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="outlined-adornment-password">Password</SignInInputLable>
                                            <TextField
                                                onChange={this.handlePasswordChange}
                                                data-test-id="txtInputPassword1"
                                                variant="outlined"
                                                id="outlined-adornment-password"
                                                value={this.state.password}
                                                style={{ marginTop: '4px', width: '100%' }}
                                                type={this.state.enablePasswordField ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.handleEnablePassword()}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }}
                                                placeholder="*********"
                                            />
                                        </Box>
                                        <Box style={{ margin: '20px 50px' }}>
                                            <SignInInputLable htmlFor="outlined-adornment-password">{configJSON.confirm}</SignInInputLable>
                                            <TextField
                                                data-test-id="txtInputConfirmPassword"
                                                helperText={this.state.errorObj.confirmPasswordError}
                                                variant="outlined"
                                                id="outlined-adornment-password"
                                                onChange={this.handleConfirmPassword}
                                                style={{ marginTop: '4px', width: '100%' }}
                                                value={this.state.confirmPassword}
                                                type={this.state.enableConfirmPasswordField ? 'text' : 'password'}
                                                error={!!this.state.errorObj.confirmPasswordError}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={this.handleClickConfirmShowPassword}
                                                            edge="end"
                                                        >
                                                            {this.handleEnableConfirmPassword()}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }}
                                                placeholder="*********"
                                            />
                                        </Box>
                                        <Box style={{ display: 'flex', margin: '20px 50px', justifyContent: 'space-between' }}>
                                            <Box>
                                                <SignInInputLable style={{ marginBottom: '4px' }}>{configJSON.skills}</SignInInputLable>
                                                <FormControl variant="outlined">
                                                    <Select
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        style={{ minWidth: '140px' }}
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.skill}
                                                        onChange={this.handleSkillChange}
                                                        placeholder="skill"
                                                    >
                                                        <MenuItem value="" disabled>
                                                            {configJSON.selectSkill}
                                                        </MenuItem>
                                                        {this.state.skills.map((item) => (
                                                            <MenuItem value={item.id}>{item.attributes.name}</MenuItem>
                                                        ))}
                                                    </Select>

                                                    {this.state.errorObj.skillError && (
                                                        <p style={{ color: 'red' }}>{this.state.errorObj.skillError}</p>
                                                    )}
                                                </FormControl>
                                            </Box>

                                            <Box>
                                                <SignInInputLable style={{ marginBottom: '4px' }}> {configJSON.years}</SignInInputLable>
                                                <FormControl variant="outlined">
                                                    <Select
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        style={{ minWidth: '180px' }}
                                                        id="demo-simple-select-outlined"
                                                        value={this.state.yearOfExperience}
                                                        placeholder="experience"
                                                        onChange={this.handleExperienceChange}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            {configJSON.experience}
                                                        </MenuItem>
                                                        <MenuItem value={0}>{"0"}</MenuItem>
                                                        <MenuItem value={1}>{configJSON.one}</MenuItem>
                                                        <MenuItem value={2}>{configJSON.two}</MenuItem>
                                                        <MenuItem value={3}>{configJSON.three}</MenuItem>
                                                        <MenuItem value={4}>{configJSON.four}</MenuItem>
                                                        <MenuItem value={5}>{configJSON.five}</MenuItem>
                                                        <MenuItem value={6}>{configJSON.six}</MenuItem>
                                                        <MenuItem value={7}>{configJSON.seven}</MenuItem>
                                                        <MenuItem value={8}>{configJSON.eignt}</MenuItem>
                                                        <MenuItem value={9}>{configJSON.nine}</MenuItem>
                                                        <MenuItem value={10}>{configJSON.ten}</MenuItem>
                                                        <MenuItem value={11}>{configJSON.tenPlus}</MenuItem>
                                                    </Select>

                                                    {this.state.errorObj.experienceError && (
                                                        <p style={{ color: 'red' }}>{this.state.errorObj.experienceError}</p>
                                                    )}
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                marginLeft: '50px',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 20,
                                                marginRight: '50px'
                                            }}>
                                            <Box
                                                sx={{

                                                    alignItems: 'center',
                                                    fontSize: "13px",
                                                    fontWeight: "bold",
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    data-test-id={"btnRememberMe1"}
                                                    color="primary"
                                                    onClick={this.handleTermsAndCondition}
                                                    style={{
                                                        padding: 0
                                                    }}
                                                    checked={this.state.checkedRememberMe}
                                                />{" "}


                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                        color: '#2967ff',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#2967ff',
                                                        marginLeft: '8px',
                                                        fontWeight: 400,
                                                    }}
                                                    onClick={this.handleTermsAndCondition}
                                                >Accept the Terms of Service</Typography>
                                            </Box>
                                        </Box>
                                        <WaitListButtonBox style={{ margin: '10px 50px', marginBottom: "0px" }}>
                                            <WaitListButton disabled={!this.state.isAllFieldsFilled} onClick={this.newUserSignup} data-test-id={"btnSignup1"} style={{ width: '100%', backgroundColor: '#2967ff' }} variant="contained" color="primary">
                                                Join the Waitlist
                                            </WaitListButton>
                                        </WaitListButtonBox>
                                        <DontHavenAccountBox>
                                            <Typography style={{ fontSize: '16px', fontFamily: "Lato" }}>Already have an account?<LoginText underline="none" data-test-id="contraLogin" onClick={() => this.handleLogInNaviigation()} style={{ cursor: "pointer", fontSize: '16px', fontWeight: 'bold', fontFamily: "Lato" , marginLeft:"2px" }}>{` Sign in`}</LoginText></Typography>
                                        </DontHavenAccountBox>
                                    </Box>
                                }

                            </Grid>
                            <Grid item md={4}>
                                <ImageBox>
                                    <PersonIcon className="toHideInMobile" src={personLogo} />
                                    <GirlImage className="toHideInMobile" src={girlImg} />
                                    <BoyImage className="toHideInMobile" src={boyImg} />
                                </ImageBox>
                            </Grid>
                        </Grid>
                        <SignUpBackgroundImage className="toHideInMobile" src={signUpbackgroundTwo} />
                    </SignUpPageBox>
                </ThemeProvider >
            </>
        );
    }
}

const SignUpPageBox = styled(Box)({
    position: "relative",
    backgroundColor: "rgb(236, 253, 253)"
})

const SignUpBackgroundImage = styled("img")({
    position: "absolute",
    right: 0,
    bottom: 0,
    zIndex: 1,
    top: "270px"
});

const ImageBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: "relative"
})

const GirlImage = styled("img")({
    height: "400px",
    position: "absolute",
    top: 230,
    left: 80,
    zIndex: 5
})

const BoyImage = styled("img")({
    height: "400px",
    position: "absolute",
    top: 160,
    right: -30,
    zIndex: 5
})

const PersonIcon = styled("img")({
    right: "180px",
    top: "220px",
    zIndex: 1,
    position: "absolute",
    height: "50px",
    width: "50px"
})

const CustomTab = styled(Box)({
    display: "flex",
})

const ActiveButton = styled("button")({
    width: "100%",
    display: "flex",
    padding: "16px",
    height: "72px",
    alignItems: "center",
    justifyContent: "center",
    color: "#2966FF",
    gap: "8px",
    fontSize: "16px",
    fontFamily: "Lato",
    backgroundColor: "#ffffff",
    border: "none",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal",
})

const UnActiveButton = styled("button")({
    width: "100%",
    display: "flex",
    padding: "16px",
    height: "72px",
    alignItems: "center",
    justifyContent: "center",
    color: "#64748B",
    gap: "8px",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontStyle: "normal",
    background: "#F8FAFC",
    lineHeight: "24px",
    border: "none",
    boxShadow: "4px -4px 4px 0px rgba(130, 133, 148, 0.05) inset",
})

const SignInButtonBox = styled(Box)({
    margin: "20px 50px"
})

const SignInButton = styled(Button)({
    fontFamily: "Lato",
    color: "#ffffff !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    width: "358px",
    display: "flex",
    padding: "10px 16px",
    height: "56px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    gap: "8px",
    backgrounColord: "#2966FF",
    textTransform: "none"
})

const WaitListButtonBox = styled(Box)({
    margin: "20px 50px"
})

const WaitListButton = styled(Button)({
    fontWeight: 700,
    width: "358px",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    display: "flex",
    fontFamily: "Lato",
    height: "56px",
    color: "#ffffff !important",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    backgrounColord: "#2966FF",
    borderRadius: "8px",
    textTransform: "none",
})

const DontHavenAccountBox = styled(Box)({
    textAlign: 'center',
    marginTop: "18px",
    marginBottom: "23px"
})

const LoginText = styled(Link)({
    textAlign: "center",
    color: "#2966FF",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Lato",
    fontStyle: "normal",
})

const SignInInputLable = styled(InputLabel)({
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    color: "#1E293B",
    fontWeight: 400,
    lineHeight: "24px",
})

const SelectDropDwon = styled(Select)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:hover fieldset": {
            border: "none",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "black",
        },
        "&.Mui-focused fieldset": {
            border: "none",
            color: "#000000",
            outline: 0
        },
        padding: "10px"
    },
    "& .MuiInput-root": {
        width: "50px",
        height: "30px",
        backgroundColor: "#FFFFFF",
        color: "black",
        padding: "10px"
    }
})