// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
  Checkbox,
  Popover
} from "@material-ui/core";
import { createTheme, ThemeProvider, Theme, withStyles } from "@material-ui/core/styles";
import { cameraicon } from './assets'
import Header from "../../emailnotifications2/src/TopHeader.web";
import { Country, City }  from 'country-state-city';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Loader from "../../../components/src/Loader.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: "#2966FF"}} />;

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
export const EditStyle:any = (theme: Theme) => ({

  ceripopuptitle: {
    color: "#000",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    display: 'flex',
    justifyContent: 'space-between'
  },
  submitBtn: {
    display: "flex",
    width: "168px",
    height: "56px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    background: '#2966FF'
  },
  addBtn: {
    display: "flex",
    width: "168px",
    height: "54px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '8px',
    background: '#2966FF',
    color: '#FFF',
    border: '1px solid #2966FF',
    "&:hover": {
      background: '#2966FF !important',
      color: '#FFF !important',
    }
  },
  cancelBtn: {
    display: "flex",
    width: "168px",
    height: "54px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '8px',
    background: '#FFF',
    color: '#2966FF',
    border: '1px solid #2966FF',
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '56px 0 0',
    gap: 16,
  },
  certifyInputLabel: {
    color: '#0F172A',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    paddingBottom: 5,
  },
  certifyTextField1: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #CBD5E1',
      borderRadius: 8,
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: '#2966FF !important',
      },
    },
  },
  profileSection: {
    marginTop: 30,
  },
  profileWrapStyles: {
    position:"absolute",
    height: 132,
    width: 132,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: '4px solid #2966FF',
    '& img': {
      height: '100%',
      width: '100%',
      borderRadius: '50%',
    },
  },
  editSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  editPictures: {
    position: 'relative',
    height: 140,
    width: 140,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    '& h6': {
      fontSize: 14,
      fontWeight: 700,
      margin: '5px 0',
    },
  },
  passwordSec: {
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 14,
      top: 24,
    }
  },
  countryField: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 0
    }
  },
  textFieldsWrapper : {
    borderLeft: '1px solid #DBDBDB',
      "@media(max-width: 967px)":
      { 
        borderLeft: 'none'
      }
  }
})


export const truthyValue = (key: any) => {
  if (key !== "" || key !== null) {
    return key
  } else {
    return ""
  }
}

import CustomisableUserProfilesController, {
  CountryCodesInterface,
  Props,
} from "./CustomisableUserProfilesController";

class EditProfile extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);

  }

  getAllCountries = Country.getAllCountries();

  getCityVal = () => {
    const countries = this.getAllCountries.find((obj) => obj.name === this.state.country)
    const region =  countries !== undefined && this.getCityByCountry(countries?.isoCode)
    const cityVal = region && region.find(obj => obj.name === this.state.cityData)
    return cityVal
  }

  getCityByCountry = (country: string) => {
    let cityArray: any[] = []
    const cities = City.getCitiesOfCountry(country);
    if (cities) {
      cityArray = cities
    }
    return cityArray
  }
  errorText=(key:boolean,message:string) =>{
    if(key){
      return <div style={{ color: 'red' }}>{message}</div>
    }
    return <></>
  }

  yearOfExpOpt = () => {
    const expData =  [
      { label: '<1', value: 0},
      { label: 1, value: 1},
      { label: 2, value: 2},
      { label: 3, value: 3},
      { label: 4, value: 4},
      { label: 5, value: 5},
      { label: 6, value: 6},
      { label: 7, value: 7},
      { label: 8, value: 8},
      { label: 9, value: 9},
      { label: 10, value: 10},
      { label: '10+', value: 11},
    ]

    return expData
  }

  countryCodeDropdownNew() {
    return (
      <InputAdornment style={{
        marginRight: "0px",
        paddingLeft: "-8px"
      }} position="start">
        <Box
          style={{
            color: "#2966FF",
            marginRight: "0px",
            paddingLeft: "-8px",
          }}
        >
          <div onClick={(e) => this.setState({ anchorElePopOver: e.currentTarget, openPopOver: true })} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
            <p>{this.state.selectedCountryCode} </p>
            <KeyboardArrowDownIcon style={{ marginTop: "13px",  color: "rgba(0, 0, 0, 0.54)" }} />
          </div>

          <Popover
            open={this.state.openPopOver}
            anchorEl={this.state.anchorElePopOver}
            onClose={() => this.setState({ anchorElePopOver: null, openPopOver: false })}
            style={{
              maxWidth: "100px",
              maxHeight: "250px"
            }}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >

            {this.state.countryCodesData.map((item: CountryCodesInterface) => {
              return (
                <MenuItem data-test-id='countryTestId' onClick={this.handleCountryChange}
                  value={item.attributes.country_code}
                  style={{ width: "80px", cursor: "pointer", padding: "5px" }} >
                  +{item.attributes.country_code}
                </MenuItem>
              )
            })}

          </Popover>
        </Box>
      </InputAdornment>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Header />
        {this.state.pageLoader ? <Loader loading={true} /> :
          <Container maxWidth={"lg"}>
            <div className={classes.profileSection}>
              <Grid container spacing={3}>
                <Grid item md={2} sm={12}>
                  <Box className={classes.editSection} style={{ position: 'relative' as const}}>
                    <label htmlFor="profilePicInput">
                      <Box className={classes.profileWrapStyles}>
                        <img src={this.state.profileImg} className={classes.profileimg} />
                      </Box>
                      <Box className={classes.editPictures} style={{ flexDirection: 'column'}}>
                        <img src={cameraicon} className={classes.cameraIcon} />
                        <Typography variant="subtitle1">Edit Picture</Typography>
                      </Box>
                    </label>
                    <input
                      id="profilePicInput"
                      type="file"
                      data-testid="img"
                      style={{ display: 'none' }}
                      onChange={this.handleEditImageChange}
                    />
                  </Box>
                </Grid>
                <Grid item md= {10} sm={12} className={classes.textFieldsWrapper}>
                  <Grid container spacing={3}>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>First Name</InputLabel>
                      <TextField id="outlined-basic" name="first_name" data-testid="fName" onChange={(e: any) => this.onEditFieldChange(e)} className={classes.certifyTextField1} value={truthyValue(this.state.editProfileData.first_name)} placeholder='First Name' variant="outlined" />
                      {this.errorText(this.state.editProfileErrorFields.first_name,"Please enter First Name")}
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>Last Name</InputLabel>
                      <TextField id="outlined-basic" name="last_name" onChange={this.onEditFieldChange} value={truthyValue(this.state.editProfileData.last_name)} className={classes.certifyTextField1} placeholder='Last Name' variant="outlined" />
                      {this.errorText(this.state.editProfileErrorFields.last_name,"Please enter Last Name")}

                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>Email</InputLabel>
                      <TextField id="outlined-basic" disabled className={classes.certifyTextField1} value={truthyValue(this.state.editProfileData.email)} placeholder='Email Address' variant="outlined" />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>Password</InputLabel>
                      <Box className={classes.passwordSec} style={{ position: 'relative' as const}}>
                        <TextField type={this.state.updatePasswordShow? 'text': 'password'} 
                          onChange={this.onPasswordUpdateChange}
                          autoComplete="off"
                          id="update-new-password" 
                          className={classes.certifyTextField1} 
                          placeholder='********' variant="outlined"  
                          error={!!this.state.editProfileErrorFields.password}
                          helperText={this.state.editProfileErrorFields.password}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">
                            <IconButton onClick={this.onShowPasswordIconClick} edge="end" style={{ fontSize: "small" }}> 
                              {(this.state.updatePasswordShow) ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                          }}
                        />
                        {this.errorText(this.state.editProfileErrorFields.password,"Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character.")}
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>Phone</InputLabel>
                      <TextField
                      InputProps={{ startAdornment: this.countryCodeDropdownNew() }}
                      value={truthyValue(this.state.editProfileData.phone_number)}
                      onChange={this.onEditFieldChange}
                      className={classes.certifyTextField1}
                      name='phone_number'
                      placeholder="8488xxxxxx"
                      data-test-id="txtInputLastName"
                      variant="outlined"
                      id="outlined-start-adornment"
                      />
                    {this.errorText(this.state.editProfileErrorFields.phone_number, "Please enter Mobile Number")}

                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label className={classes.certifyInputLabel}>Available</label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled
                          value={this.state.editProfileData?.is_available ? 1 : 2}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={2}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label id="demo-simple-select-label" className={classes.certifyInputLabel}>Country</label>

                        <Autocomplete
                          disableClearable={true}
                          id="combo-box-demo"
                          className={classes.countryField}
                          options={this.getAllCountries}
                          getOptionLabel={(option: any) => option.name}
                          style={{ padding: "20px !important",height:"46px"}}
                          data-testid="country"
                          value={this.state.country !== "" && this.getAllCountries.find((obj) => obj.name === this.state.country)}
                          onChange={(event: any, newValue: any) => this.setState({ region : newValue?.isoCode, country : newValue?.name, cityData: ""})}
                          renderInput={(params) => <TextField {...params} variant="outlined" style={{padding:0}}/>}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label id="demo-simple-select-label" className={classes.certifyInputLabel}>City</label>
                        <Autocomplete
                          disableClearable={true}
                          id="combo-box-demo"
                          className={classes.countryField}
                          options={this.state.region !== "" ? this.getCityByCountry(this.state.region) : []}
                          getOptionLabel={(option:any) => option.name}
                          value={this.state.cityData !== "" && this.getCityVal()}
                          style={{ padding: 0 }}
                          data-testid="city"
                          onChange={(event: any, newValue: any) => this.setState({ cityData : newValue?.name})}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label id="demo-simple-select-label" className={classes.certifyInputLabel}>Platform</label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.selectedDomain.name}                                                   
                        >
                          {this.state.skillsData.map((it: any) => {
                            return(
                              <MenuItem onClick={() => this.onDomainChange(it)} key={it.id} value={it.attributes.name}>{it.attributes.name}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label id="demo-simple-select-label" className={classes.certifyInputLabel}>Years of Experience</label>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.editProfileData.year_of_experience ? parseInt(this.state.editProfileData.year_of_experience) : "none"}

                          name="year_of_experience"
                          data-testid="year_of_experience"
                          onChange={this.onEditFieldChange}
                        >
                          <MenuItem value={"none"} disabled>Select</MenuItem>
                          {this.yearOfExpOpt().map((it: any) => {
                            return (
                              <MenuItem key={it.value} value={it.value}>{it.label}</MenuItem>
                            )
                          })}

                        </Select>
                        {this.errorText(this.state.editProfileErrorFields.year_of_experience,"Please enter Experience Details")}

                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <FormControl className={classes.certifyTextField1} fullWidth variant="outlined">
                        <label id="demo-simple-select-label" className={classes.certifyInputLabel}>Subdomain</label>
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="checkboxes-tags-demo"
                          className={classes.countryField}
                          options={this.state.subSkillsData}
                          disableCloseOnSelect
                          value={this.state.defaultSubskills}                                                 
                          getOptionLabel={(option: any) => option.attributes.name}
                          onChange={(e:any, newVal: any) => this.changeSubDomain(newVal)}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.attributes.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Subdomain" />
                          )}                         
                        />

                      </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <InputLabel className={classes.certifyInputLabel}>Hour Rate</InputLabel>
                      <TextField id="outlined-basic" data-testid="contractorPrice" name="contractor_price" onChange={this.onEditFieldChange} value={truthyValue(this.state.editProfileData.contractor_price)} className={classes.certifyTextField1} placeholder='Hour Rate' variant="outlined" />
                      {this.errorText(this.state.editProfileErrorFields.contractor_price,"Please enter Hourly Rate")}
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.dialogActions}>
                        <Button className={classes.cancelBtn} style={{textTransform: 'capitalize'}} onClick={this.handleCloseEdit} variant="outlined">
                          Cancel
                        </Button>
                        <Button className={classes.addBtn} data-testid="saveBtn" style={{textTransform: 'capitalize'}} onClick={this.validateUserForm} variant="contained" >
                          Save
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>}
      </ThemeProvider>
    );
  }
}

export { EditProfile }
export default withStyles(EditStyle)(EditProfile);
// Customizable Area End
