import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { profileLogo } from "../../../components/src/assets"
import { cropImage } from "../../emailnotifications2/src/assets";
interface PlatformProps  {
  type: string;
  id: string;
  attributes: {
    name: string;
    sub_skills: []
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  userName: string;
  showContractorModal: boolean;
  modalContractorType: string;
  question : string,
  platform : string | number;
  contractorData: any;
  alertMsgType: any;
  snackbarOpen: boolean;
  alertMsg: string;
  platFormArr: any;
  subCategory: any;
  subCategoryArr: any;
  recentEngagementRecords: any;
  freeTrialModel: boolean;
  cardsData : any;
  defaultPlatform: PlatformProps 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitQuestionAPiId: string = "";
  submitRatingAPiId: string = "";
  getSkillsDataAPiId: string= "";
  getEngagementRecordsApiCallId: string = "";
  getCardsApiCallId: string = "";
  addCardsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      token: "",
      showContractorModal: false,
      modalContractorType: 'loader',
      question: '',
      platform: '',
      contractorData: [],
      alertMsgType: 'error',
      snackbarOpen: false,
      alertMsg: '',
      subCategory: '',
      platFormArr: [],
      subCategoryArr: [],
      recentEngagementRecords: [],
      freeTrialModel: false,
      cardsData: [],
      userName: "",
      defaultPlatform: {} as PlatformProps 
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleAPIResponse(apiRequestCallId, responseJson)
      if(responseJson.status === 500){
        this.setState({
          showContractorModal: false,
          modalContractorType: 'loader',
          snackbarOpen: true,
          alertMsgType: 'error',
          alertMsg: 'Something went wrong'
        }, () => {
          this.props.history.replace("landing")
        });
        return "";
      }
      if (apiRequestCallId === this.submitQuestionAPiId) {
        this.onContractorsApiSucc(responseJson)
      }
      if(apiRequestCallId === this.submitRatingAPiId){
        this.getEngageMentRecordsData();
        this.setState({
          snackbarOpen: true,
          alertMsgType: 'success',
          alertMsg: 'Thanks for your feedback!',
          showContractorModal: false,
          modalContractorType: 'loader'
        }, () => {
          this.props.history.replace("landing")
        })
      }
      if(apiRequestCallId === this.getSkillsDataAPiId){     
        this.onSkillsApiSucc(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onContractorsApiSucc = async (responseJson: any) => {
    if (responseJson.data) {
      const cntrctrData = await Promise.all(responseJson.data.map(async (item: any) => {
        if (item && item.attributes.photo) {
          const imgUrl = await cropImage(item.attributes.photo, true);
          item.attributes.photo = imgUrl;              
        }
        return item;
      }));

      this.setState({contractorData: cntrctrData })
    } 
    if(responseJson.errors && responseJson.errors[0]?.account && responseJson.errors[0]?.account ==="Account Not Found"){
      this.props.history?.push("")
    }  
  }
  async componentDidMount(){
    this.isAuthenticated();
    this.getSkillsData();
    if(this.props.location.state?.modalType){
      const { modalType } = this.props.location.state;
      this.setState({ modalContractorType: modalType, showContractorModal: true });
    }
    this.getEngageMentRecordsData()
    this.getCardsRecords()
  }

  callAnApi(props:any){
    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(props.headers));
    if(props.body){
      getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(props.body));
    }
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),props.requestType);
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    return getValidationsMsg.messageId;
  }

  getToken(){
    const token = localStorage.getItem('signin-token');
    return token;
  }

  getSkillsData = () => {
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.getSkillsDataAPiId = this.callAnApi({
      headers: headers,      
      endPoint: "skills",
      requestType: "GET"
    })
  }

  onSkillsApiSucc = (responseJson: any) => {
    if (responseJson.data) {
      const platforms = responseJson.data;
      const findSalesForce = platforms.find((value: any) =>  value.attributes.name == "Salesforce");
      this.setState({
        platFormArr: responseJson.data,
        platform: findSalesForce.id,
        defaultPlatform:findSalesForce,
        subCategoryArr: findSalesForce.attributes.sub_skills.data 
      })
    }
  }
  redirectToEngagementDetailPage = (data: any) => {

     // Fullname
     let firstNameContra: string = ""
     let lastNameContra: string = ""
     if (data.attributes.contractor.data && data.attributes.contractor.data.attributes.first_name) {
         firstNameContra = data.attributes.contractor.data.attributes.first_name
     }
     if (data.attributes.contractor.data && data.attributes.contractor.data.attributes.last_name) {
         lastNameContra = data.attributes.contractor.data.attributes.last_name
     }
     const FullNameContra = firstNameContra + " " + lastNameContra;

     // ProfileImg
     let profileImgContractor: string = profileLogo
     if (data.attributes.contractor.data && data.attributes.contractor.data.attributes.photo) {
      profileImgContractor = data.attributes.contractor.data.attributes.photo
     
    }

    // Rating
    let contraRating: number = 0
    if (data.attributes.contractor.data && data.attributes.contractor.data.attributes.rating) {
     contraRating = data.attributes.contractor.data.attributes.rating
    
   }

    const requestListdataContra = {
      "dataList": [
        {
          "img": profileImgContractor,
          "name": FullNameContra,
          "durationTime": "-",
          "engagementRequestMsg": data.attributes.engagement_request,
          "paymentStatusList": {
            "title": "Pending",
            "backgroundColor": "rgba(255, 41, 41, 0.20)",
            "fontColor": "#DC2626"
          },
          "platformNameList": data.attributes.platform,
          "totalCostList": data.attributes.total_cost,
          "roomId": data.attributes.room_id,
          "contractorId" : data.attributes.contractor.data.id,
          "rating": contraRating
        }
      ]
    }

    this.props.history.push({
      pathname: "/EngagemenRequest",
      state: {engageMentRecords : data, requestData : requestListdataContra},
    });
  }
  closeAlertMsg = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      snackbarOpen: false,
      alertMsgType: '',
      alertMsg: ''

    })
  }
  
  isAuthenticated = async () =>  {
    const token = this.getToken();
    if(token){
      const localStorageUserData: any = localStorage.getItem("userData"); 
      const userData = JSON.parse(localStorageUserData);
      this.setState({token: token, userName: userData.first_name})
    }else{
      this.props.navigation.navigate("Home")
    }
  }

  handlePostQuestionObj = (event: any) => {
    const targetName = event.target.name
    const targetVal = event.target.value
    this.setState((prevState) => ({...prevState, [targetName] : targetVal}));
  }

  onSelectPlatform = (itemData: any) => {
    this.setState({
      subCategory: '',
      platform:itemData.id,
      defaultPlatform: itemData,
      subCategoryArr: itemData.attributes.sub_skills.data
    })
  }

  submitQuestion = async () => {
   
    if(this.state.cardsData.length > 0) {

    this.setState({
      showContractorModal: true,
      modalContractorType: 'loader'
    });
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    const postBody = {
      question: this.state.question,
      platform: Number(this.state.platform),
      sub_category: Number(this.state.subCategory)
    }
    this.submitQuestionAPiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: "accounts/search_top_contractor",
      requestType: "POST"
    })

  } else {
     this.setState({ freeTrialModel : true})
  }
    
  }

  onPregressDone = () => {
    this.setState({       
      modalContractorType: 'contractor'
    })
  }

  closeContarctorModal = () => {
    this.setState({
      showContractorModal: false,
      modalContractorType: 'loader',
      contractorData: []
    }, () => this.props.history.replace("landing"))    
  }
  onSubmitSelectedContractor = () => {
    this.setState({
      modalContractorType: 'rating'
    })
  }
  onSubmitRating = (postBody:any) => {
    const headers= {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.submitRatingAPiId = this.callAnApi({
      headers: headers, 
      body: postBody,
      endPoint: "reviews",
      requestType: "POST"
    })
  }

  handleAPIResponse = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.getEngagementRecordsApiCallId){
       this.setEngagementRecordsResponse(responseJson)
    } else if ( apiRequestCallId === this.getCardsApiCallId) {
       this.setCardsRecordsResponse(responseJson)
    }  else if ( apiRequestCallId === this.addCardsApiCallId) {
      this.addCardsRecordsResponse(responseJson)
   }
  }

  setEngagementRecordsResponse = async (responseJson: any) => {
    if (responseJson && responseJson.data) {  
      const engData = await Promise.all(responseJson.data.map(async (item: any) => {
        if (item.attributes.contractor.data && item.attributes.contractor.data.attributes.photo) {
          const imgUrl = await cropImage(item.attributes.contractor.data.attributes.photo, true);
          item.attributes.contractor.data.attributes.photo = imgUrl;
        }
        return item;
      }));
      this.setState({ recentEngagementRecords: engData })
    } else {
      this.setState({ recentEngagementRecords: [] })
    }
  }

  setCardsRecordsResponse = (responseJson: any) => {
    if(responseJson && responseJson.cards && responseJson.cards.length > 0 ) {
      this.setState({ cardsData: responseJson.cards})
   } else { 
      this.setState({ cardsData: []})
   }
  }

  addCardsRecordsResponse = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      this.getCardsRecords()
      this.setState({
        freeTrialModel: false
      }, () => {
        this.setState({
          snackbarOpen: true,
          alertMsgType: 'success',
          alertMsg: 'Card Added Succesfully',
        })
      });
      this.submitQuestion();
      
    } else {
      let errMsg = "Something went wrong!"
      if(responseJson && responseJson.errors && responseJson.errors[0].stripe) {
         errMsg = responseJson.errors[0].stripe
      }
      this.setState({
        freeTrialModel: false
      }, () => {
        this.setState({
          snackbarOpen: true,
          alertMsgType: 'error',
          alertMsg: errMsg,
          cardsData: []
        })
      })
    }
  }

  

  getEngageMentRecordsData = () => {
    const headers= {
      'Content-Type': 'application/json',
      token: localStorage.getItem('signin-token')
    }
    this.getEngagementRecordsApiCallId = this.callAnApi({
      headers: headers,      
      endPoint: configJSON.getEngagemnetApiEndPoint,
      requestType:configJSON.httpGETRequestMethod
    })
  }

  getCardsRecords = async () => {
    const headers= {
      'Content-Type': 'application/json',
      token: localStorage.getItem('signin-token')
    }

    this.getCardsApiCallId = this.callAnApi({
      headers: headers,
      endPoint: configJSON.getCardsRecordsApiEndPoint,
      requestType:configJSON.httpGETRequestMethod
    })
  }
   
  updateCardData = (newCard: any) =>{
    const oldCard: any = this.state.cardsData;
    oldCard.push(newCard);
    this.setState({ cardsData: oldCard })
  }
  addCardsRecords = (token : string) => {

    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem('signin-token')
    };

    const httpBody = {
      "data": {
        "attributes": {
          "token": token
        }
      }
    }

    this.addCardsApiCallId = this.callAnApi({
      headers: header,
      body: httpBody,
      endPoint: configJSON.addCardsRecordsApiEndPoint,
      requestType:configJSON.httpPOSTRequestMethod
    })
  }

  handleFreeTrialModel = () => {
    this.setState({ freeTrialModel : false})
  }

  // Customizable Area End
}
