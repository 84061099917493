import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";

interface ApiParams {
    header: string 
    endPoint: string
    method: string 
    body?: string | any
}
 
export const Logo = require('./image_logo.png');
export const profileLogo = require('./profileLogo.png');
export const greaterThan = require('./greaterThan.png');
export const termsRegex = /\d+\./
export const striprKey = "pk_live_51NhepbJxGRwQG48fsI7g7zuzXTCzCJxP3uZ9Urtt78IAvojpl6FQCGNt81i8yj73C09NsD1jHgSca6MKEVWw825f00zSAyzg4H"

export const apiCalling = ({ header, endPoint, method, body } : ApiParams) => {
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
}

export const handleErrorMsgText = (responseJson: any) => {
    const err = responseJson.errors[0].account || responseJson.errors[0].password || responseJson.errors[0].account_skills || responseJson.errors[0].errors
    return err
}