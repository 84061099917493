import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputLabel,
  Slide,
  Icon,
  Snackbar
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import Header from "../../emailnotifications2/src/TopHeader.web";
import { edit, deleteimg, add, star,Editt ,blackaddicon, crossicon} from './assets'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TransitionProps } from '@material-ui/core/transitions';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {greenTick} from "../../forgot-password/src/assets"
import Alert from '@material-ui/lab/Alert';

export const ProfileStyle = (theme: Theme) => ({
    
  profileCard: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: ' 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
    padding: '24px',
    margin: '30px 0',
  },
  profileCard1: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    background: '#FFF',
    padding: '24px',
    boxShadow: ' 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)',
    margin: '30px 0', 
    "& .MuiPaper-elevation1": {
boxShadow: 'none',
    }
  },
  proifeCardLeft: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  profileName:
  {
    color: "#21272A",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    width: '100%'
  },
  contryName:
  {
    color: "#64748B",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    display: 'flex',
    alignItem: 'center',
    gap: '10px'
  },
  profileimg: {
    border: "2px solid #2966FF", borderRadius: "100%"
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%'
  },
  row: {
    width: '100%',
    display: 'block'
  },
  editBtn: {
    borderRadius: "8px",
    border: "1px solid #2966FF",
    padding: "10px 16px",
    height: "40px",
    background: "transparent",
    color: "#2966FF",
    display: 'flex',
    alignItem: 'center',marginTop: "50px",
    fontWeight: 700,
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    "@media(max-width: 767px)": {
      marginTop: "15px"
    }
  },
  
  profileSkills:
  {
    color: "#64748B",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    display: "inline-flex",
    gap: "30px",
    paddingLeft: "15px",
    margin: "0",
    "@media(max-width: 767px)": {
      width: 'max-content'
    }
  },
  Available: {
    color: "#059669",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
  },
  NotAvailable: {
    color: "red",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
  },
  certiCard:
  {
    padding: '15px',
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.06)  !important',
    border: '1px solid rgba(0, 0, 0, 0.03)',
    margin:  '0',
    height: '70%',
    display: 'flex',
justifyContent: 'center'
  },
  certiskillcardtitle: {
    color: "#1E293B",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    display: 'flex',
    gap: '5px',
    '& img': {
        marginLeft: 5,
        height: 22,
    }
  },
  BankDetails:{
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    color: '#2966FF',
    fontFamily: 'Lato',
  },
  certiskillTitle:
  {
    color: "#000",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    marginBottom: "20px",
    display: 'flex',
    jusifyContent: 'end',
  },
  addimg:{
    width: '24px',
    height: '24px',
    marginRight: '10px'
  },
  Details:{
    flex:'1'
  },
  certiskillcardprfile: {
    color: "#21272A",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400
  },
  certiskillcardTime: {
    color: "#64748B",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400
  },
  bankTableheader: {
    padding: '12px 8px',
    color: "#64748B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
  },
  editpen:{
  display:'flex',
  gap:'7px',
  width: '24px',
height: '24px',
  },

  bankTabledata:
  {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  bankTabletr: {
    border: '1px solid #f2f2f2',
    borderWidth: '3px 0 3px 0'
  },

   // Expand Modal CSS

  submitBtnExpandModal: {
    width: "168px",
    height: 52,
    padding: "10px 16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    background: '#2966FF',
    color: '#fff',
    fontSize: 16,
    texttransform: "capitalize",
    marginRight: 16,
  },
  ceripopuptitleExpandModal: {
    color: "#000",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "32px",
    '& .MuiTypography-h6': {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    '& .MuiButtonBase-root': {
      padding: 0
    }
  },
  DialogActionsExpandModal: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  certifyInputLabelExpandModal: {
    color: '#0F172A',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    paddingBottom: 5,
  },
  certifyTextField1ExpandModal: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #CBD5E1',
      borderRadius: 8,
    }
  },
  issueLableExpandModal: {
    color: "#64748B",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },
  issueNmaeExpandModal: {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px"
  },

  noBtnExpandModal: {
    borderRadius: "8px", border: "1px solid #2966FF",
    background: '#fff',
    padding: "10px 16px",
    color: "#2966FF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    height: "56px",
    width: "150px",
    "&:hover": {
      background: '#fff',

    },
    "&:focus": {
      background: "#fff",
    },

    textTransform: 'capitalize' as const

  },
  deleteBtnExpandModal:
  {
    borderRadius: "8px", background: "#DC2626",
    height: "56px", padding: "10px 16px",
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    width: "150px",
    textTransform: 'capitalize' as const,
    "&:hover": {
      background: "#DC2626",
    },
    "&:focus": {
      background: "#DC2626",
    }

  },
  editcertiExpandModal: {
    "& .MuiDialog-paperWidthSm":
    {
      maxWidth: '450px',
    }
  },  

  // Delete Poopup CSS 

  deletepopupDeleteModal: {
    "& .MuiDialog-paperWidthSm":
    {
        maxWidth: '450px',
    }
},
  popuptitleDeleteModal: {
        color: "#2966FF",
        textAlign: "center" as const,
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        marginBottom: '24px'
    },
      
    deleteButtonDeleteModal:
    {
        borderRadius: "8px", background: "#DC2626",
        height: "56px", padding: "10px 16px",
        color: "#FFF",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        width: "50%",
        "&:hover": {
          background: "#DC2626",
      },
      "&:focus": {
          background: "#DC2626",
      }

    },
    popupdetailDeleteModal: {
        color: "#64748B",
        textAlign: "center" as const,
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        marginBottom: '24px',
        width: '100%',
        display: 'block'


    },
 
    noButtonDeleteModal: {
        borderRadius: "8px", border: "1px solid #2966FF",
        background: '#fff',
        padding: "10px 16px",
        color: "#2966FF",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        height: "56px",
        width: "50%"
    },


    // Add Modal Css

    DialogActionsAddModal: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    popuptitleAddModal: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      lineHeight: "32px",
      borderBottom: '1px solid #E2E8F0',
      '& .MuiTypography-h6': {
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 700,
        fontFamily: 'Lato',
      },
      '& .MuiButtonBase-root': {
        padding: 0
      }
    },
  
    submitButtonAddModal: {
      width: "168px",
      height: 52,
      padding: "10px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      background: '#2966FF',
      color: '#fff',
      fontSize: 16,
      texttransform: "capitalize",
      marginRight: 16,
    },
    certifyTextField1AddModal: {
      width: '100%',
      '& fieldset': {
        border: '1px solid #CBD5E1',
        borderRadius: 8,
      }
    },
    cerInpLabelAddModal: {
      color: '#0F172A',
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      paddingBottom: 5,
    },
    dateFieldsAddModal : {
      height: "50px",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      padding: "14px",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "24px",
      width: "100%",
    }, 

    // Add Bank Modal CSS
    ceripopuptitleAddBankModal: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: 'Lato',
    },
    addBtnAddBankModal: {
      display: "flex",
      width: "168px",
      height: "54px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: '8px',
      background: '#2966FF',
      color: '#FFF',
      border: '1px solid #2966FF',
    },
    cancelBtnAddBankModal: {
      display: "flex",
      width: "168px",
      height: "54px",
      padding: "10px 16px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: '8px',
      background: '#FFF',
      color: '#2966FF',
      border: '1px solid #2966FF',
    },
    dialogActionsAddBankModal: {
      display: 'flex',
      justifyContent: 'center !important',
    },
    certifyInputLabelAddBankModal: {
      color: '#0F172A',
      fontFamily: 'Lato',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      paddingBottom: 5,
    },
    certifyTextField1AddBankModal: {
      width: '100%',
      '& fieldset': {
        border: '1px solid #CBD5E1',
        borderRadius: 8, 
      },
      '& .Mui-focused': {
        '& fieldset': {
          borderColor: '#2966FF !important',
        },
      },
    },

    // Delete Bank Modal CSS

    deletepopuptileDeleteBankModal: {
      color: "#2966FF",
      textAlign: "center" as const,
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Lato",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      marginBottom: '24px'
  },
  deletepopupdetailDeleteBankModal: {
      color: "#64748B",
      textAlign: "center" as const,
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      marginBottom: '24px',
      width: '100%',
      display: 'block'


  },
  deleteBtnDeleteBankModal:
  {
      borderRadius: "8px", background: "#DC2626",
      height: "56px", padding: "10px 16px",
      color: "#FFF",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      width: "50%",
      "&:hover": {
        background: "#DC2626",
    },
    "&:focus": {
        background: "#DC2626",
    }

  },
  noBtnDeleteBankModal: {
      borderRadius: "8px", border: "1px solid #2966FF",
      background: '#fff',
      padding: "10px 16px",
      color: "#2966FF",
      fontFamily: "Lato",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
      height: "56px",
      width: "50%"
  },
  deletepopupDeleteBankModal: {
      "& .MuiDialog-paperWidthSm":
      {
          maxWidth: '450px',
      }
  },
  userDataContainer: {
    height: 132,
    display: 'flex',
    justifyContent: 'space-between'
  }
  

  
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
import Loader from "../../../components/src/Loader.web";


 class UserProfile extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.openAddCertificationModal = this.openAddCertificationModal.bind(this);
   
    // Customizable Area End
  }

  // Customizable Area Start
  
  showCertificateData = (classes: any) => {
    return(
      this.state.certificateData?.length > 0 && this.state.certificateData.map((item: any) => {
        return (
          <Grid item lg={3} md={4} sm={6} xs={12} key={item?.id}>
            <Card onClick={() => this.openEditcertificate(item?.attributes)} data-testId={`certificate-${item.id}`} className={classes.certiCard} style={{ flexDirection: 'column' as const, cursor: 'pointer' }}  >
              <Typography className={classes.certiskillcardtitle}>{item?.attributes?.name}</Typography>
              <Typography className={classes.certiskillcardprfile}>{item?.attributes?.issuing_organization}</Typography>
              <Typography className={classes.certiskillcardTime}>{this.convertDateFormat(item?.attributes?.issue_date)} - {item?.attributes?.expiration_date && this.convertDateFormat(item?.attributes?.expiration_date)} </Typography>
            </Card>
          </Grid>
        )
      })
    )
  }

   showBankAccountData = (classes: any) => {
     return (
       this.state.bankAcoountData.length > 0 ? this.state.bankAcoountData.map((item: any, index: number) => 
         <TableRow className={classes.bankTabletr} >
           <TableCell component="th" scope="row">
             {item.bank_name}
           </TableCell>
           <TableCell align="left">********{item.last_4_digit_acount_number}</TableCell>
           <TableCell align="left">{item.account_name}</TableCell>
           <TableCell align="left">{item.routing_number}</TableCell>
           <TableCell align="left"></TableCell>
           <TableCell align="left" style={{
             display: 'flex',
             justifyContent: 'end'
           }}>
             <Button className={classes.editpen} data-testid='editBtn' onClick={() => this.openEditBankDetailsModal(item)}>
               <img src={edit} />
             </Button>
             <Button className={classes.editpen} data-testid='deleteBtn'>
               <img src={deleteimg} onClick={() => this.openDeleteBankDetailsModal(item)} />
             </Button>

           </TableCell>

         </TableRow>
       )
         : <TableRow>
           <TableCell colSpan={5} align="center"><Typography style={{ fontWeight: 'bold' }}>No Data Found</Typography></TableCell>
         </TableRow>
     ) 
  }

   contractotorFulName = () => {

     // Fullname
     let item = this.state.contractorProfileData
     let firstName: string = ""
     let lastName: string = ""
     if (item.attributes && item.attributes.first_name) {
       firstName = item.attributes.first_name
     }
     if (item.attributes && item.attributes.last_name) {
       lastName = item.attributes.last_name
     }
     const FullName = firstName + " " + lastName;

     return FullName
   }

    emojiToPNG = (flagEmoji: any) => {
      const countryCode = Array.from(flagEmoji, (codeUnit:any) => codeUnit.codePointAt()).map(char => String.fromCharCode(char-127397).toLowerCase()).join('')
      return (<img src={`https://flagcdn.com/24x18/${countryCode}.png`} alt='country-flag' />)
    }
   contractotorCiltyAndCountry = () => {

    // Fullname
    let item = this.state.contractorProfileData
    let city: string = ""
    let country: string = ""
    if (item.attributes && item.attributes.city) {
      city = item.attributes.city + ", "
    }
    if (item.attributes && item.attributes.country && item.attributes.country!=="undefined") {
      country = item.attributes.country
    }

    const fulltext = city + country;
    return <React.Fragment> {country ? <span style={{ marginTop: 'auto', marginBottom: 'auto'}}>{this.emojiToPNG(this.state.countryFlag)}</span>: "" } {fulltext}</React.Fragment>
  }

   contractotorSkills = () => {

    // Fullname
    let item = this.state.contractorProfileData
    let skills: string = "-"
    if (item.attributes?.skills && item.attributes?.skills.length > 0) {
      skills = item.attributes.skills[0].name
    }
    
    return skills
  }

  contractotorYearOfExp = () => {

    // Fullname
    let item = this.state.contractorProfileData
    let yearOfExp: number = 0
    if (item.attributes && item.attributes.year_of_experience) {
      yearOfExp = item.attributes.year_of_experience
    }
   

    return yearOfExp ===0 ? "<1" : yearOfExp
  }

  contractotorRatings = () => {

    // Fullname
    let item = this.state.contractorProfileData
    let rating: number = 0
    if (item.attributes && item.attributes.rating) {
      rating = item.attributes.rating
    }
  
    return rating
  }

  contractotorAvailibility = () => {

    // Fullname
    let item = this.state.contractorProfileData
    let isAvailable: boolean = false
    if (item.attributes && item.attributes.is_available) {
      isAvailable = item.attributes.is_available
    }

    return isAvailable
  }

  availableText = () => {
    
    let availableText = "Available";
    let notAvailableText = "Unavailable"

    return this.contractotorAvailibility() ? availableText : notAvailableText
  }

  handleAvailibilityClasses = (classes: any) => {
    return this.contractotorAvailibility() ? classes.Available : classes.NotAvailable
  }

  handleImageDisplay = (classes: any) => {
    return this.state.profileImg !== null ?
      <img src={this.state.profileImg} style={{ height: 120, width: 120, border: "5px solid #2966FF", background: "rgb(203, 213, 225)" }} className={classes.profileimg}></img>
      :
      <Icon>
        <PersonOutlineOutlinedIcon style={{ height: 120, width: 120, border: "5px solid #2966FF", background: "rgb(203, 213, 225)" }} className={classes.profileimg} />
      </Icon>
    
  }

  showErrorMsg=(key:boolean,message:string) =>{
    if(key){
      return <div style={{ color: 'red' }}>{message}</div>
    }
    return <></>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Snackbar style={{ top: "80px", boxShadow: "0px 6px 15px -3px #00000026"}} open={this.state.showSuccMsg} data-test-id="closeSnackBar"  onClose={this.onSnackBarClose} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity={this.state.messageType} style={{ backgroundColor: "white", borderRadius: "8px",display: "flex", alignItems: "center"}} 
          iconMapping={{ success:<img src={greenTick} width={ 20 } height={20} />}}>
            <Typography>{this.state.messageDesctiption}</Typography>
          </Alert>
        </Snackbar>
        {(this.state.contractorProfileData?.attributes && this.state.contractorProfileData?.attributes.first_name) ? "" : <Loader loading ={true}/>}
        <div className={classes.container}>
          <div className={classes.row}>
            <Box className={classes.profileCard}>
              <Box className={classes.proifeCardLeft}>
                <Box className={classes.titleWrapStyles}>
                  {this.handleImageDisplay(classes)}
                </Box>
                <Box style={{ flexDirection: 'column'}} className={classes.userDataContainer}>
                  <Typography className={classes.profileName}>{this.contractotorFulName()}</Typography>
                  <Typography className={classes.contryName}>                   
                     {this.contractotorCiltyAndCountry()}</Typography>
                  <ul className={classes.profileSkills}>
                    <li>
                      <Typography>{this.contractotorSkills()} </Typography>
                    </li>
                    <li>
                      <Typography>{this.contractotorYearOfExp()} Years of Experience</Typography>

                    </li>
                    <li>
                      <Typography>
                        <img src={star} />
                        {this.contractotorRatings()}</Typography>
                    </li>
                  </ul>

                  <Typography className={this.handleAvailibilityClasses(classes)}>{this.availableText()}</Typography>

                </Box>

              </Box>
              <Button className={classes.editBtn} style={{ textTransform: "none"}} onClick={this.handleEditProfileClick} >
                <img src={Editt} />   
                Edit Profile
              </Button>
            </Box>
            <Box className={classes.profileCard1}  style={{flexDirection: 'column'as const}}>
              <Typography className={classes.certiskillTitle}>Certifications</Typography>
              <Grid container spacing={2}>
                {this.showCertificateData(classes)}
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Card className={classes.certiCard} style={{ flexDirection: 'column' as const }}>
                    <Button data-testId='addCertificateModalBtn' onClick={(e) => this.openAddCertificationModal(e)}
                      className={classes.certiskillcardtitle} style={{ fontWeight: 'normal' as const, textTransform: 'unset' }}>Add a Certification
                      <img src={blackaddicon} />
                    </Button>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.profileCard1}  style={{flexDirection: 'column'as const}}>
              <Typography
               className={classes.certiskillTitle}><strong className={classes.Details}>Bank Details</strong>  
              <Button onClick={this.openBankDetailsModal} data-testid="addBankBtn" > <img className={classes.addimg} src={add} /><span  className={classes.BankDetails} style={{textTransform: "capitalize"}}>Add Bank Details</span></Button>
              </Typography>


              <TableContainer component={Paper}>
                <Table aria-label="simple table" className={classes.bankTable}>
                  <TableHead>

                    <TableRow>

                      <TableCell className={classes.bankTableheader} style={{ textAlign: 'left' as const }} align="right">Bank Name</TableCell>
                      <TableCell className={classes.bankTableheader} style={{ textAlign: 'left' as const }} align="right">Account Number</TableCell>
                      <TableCell className={classes.bankTableheader} style={{ textAlign: 'left' as const }} align="right">Account Holder Name</TableCell>
                      <TableCell className={classes.bankTableheader} style={{ textAlign: 'left' as const }} align="right">Routing Number</TableCell>
                      <TableCell component="th" scope="row">

                      </TableCell>
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    {this.showBankAccountData(classes)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </div>

        {/* Add Modal Start here */}
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.popuptitleAddModal} id="alert-dialog-slide-title">
            Add Certificate
            <Button type="button" onClick={this.handleClose}>
              <img src={crossicon} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Name</InputLabel>
                  <TextField value={this.state.addCertificateForm.name} name='name' data-testId="addName" onChange={(e) => this.handleFormFields(e)} className={classes.certifyTextField1AddModal} placeholder='Ex:Salesforce Management' variant="outlined" />
                  {this.state.certificateErrors.name && <div style={{ color: 'red' }}>Please enter name</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Issuing Organization</InputLabel>
                  <TextField value={this.state.addCertificateForm.issuing_organization} name='issuing_organization' data-testId="addOrgName" onChange={(e) => this.handleFormFields(e)} className={classes.certifyTextField1AddModal} placeholder='Ex:Salesforce' variant="outlined" />
                  {this.state.certificateErrors.issuing_organization && <div style={{ color: 'red' }}>Please enter organisation name</div>}
                </Grid>
                <Grid item xs={12}>
                  <label className={classes.cerInpLabelAddModal}>Issue Date</label>
                  <DatePicker
                    className={classes.dateFieldsAddModal}
                    placeholderText='Issue Date'
                    value={this.state.issueDate}
                    required
                    data-testId="addIssueDate"
                    dateFormat="dd-MM-yyyy"
                    onChange={(date: any) => this.handleIssueDateFields(date)} />
                  {this.state.certificateErrors.issue_date && <div style={{ color: 'red' }}>Please select date</div>}
                </Grid>
                <Grid item xs={12}>
                  <label className={classes.cerInpLabelAddModal}>Expiration date</label>
                  <DatePicker
                    className={classes.dateFieldsAddModal}
                    placeholderText='Expiration Date'
                    value={this.state.expDate}
                    required
                    data-testId="addExpDate"
                    dateFormat="dd-MM-yyyy"
                    minDate={this.state.minDateExp}
                    onChange={(date: any) => this.handleExpDateFields(date)} />
                  {this.state.certificateErrors.expiration_date && <div style={{ color: 'red' }}>Please select date</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Credential ID</InputLabel>
                  <TextField value={this.state.addCertificateForm.credential_id} name='credential_id' data-testId="addCredId" onChange={(e) => this.handleFormFields(e)} id="outlined-basic1" className={classes.certifyTextField1AddModal} variant="outlined" />
                  {this.state.certificateErrors.credential_id && <div style={{ color: 'red' }}>Please enter Credential ID</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Credential URL</InputLabel>
                  <TextField value={this.state.addCertificateForm.credential_url} name='credential_url' data-testId="addCredUrl" onChange={(e) => this.handleFormFields(e)} id="outlined-basic2" className={classes.certifyTextField1AddModal} variant="outlined" />
                  {this.state.certificateErrors.credential_url && <div style={{ color: 'red' }}>Please enter Credential URL</div>}
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#fff", background: "#2966FF", textTransform: "none" }} className={classes.submitButtonAddModal} type="button" data-testId="addSubmitButton" onClick={() => this.addCertificateData()}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Modal End here */}
       
         {/* Expand Modal Start Here */}
        <Dialog className={classes.editcertiExpandModal}
          open={this.state.Isvisible}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleEditcertificateClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes?.ceripopuptitleExpandModal} id="alert-dialog-slide-title">
             {this.state.certificateItem?.name}
            <Button type="button" onClick={this.handleEditcertificateClose}>
              <img src={crossicon} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.issueLableExpandModal}>Issuing Organization</Typography>
                    <Typography className={classes.issueNmaeExpandModal}>{this.state.certificateItem?.issuing_organization}</Typography>
                  
                   </Grid>
                <Grid item xs={6}>
                <Typography className={classes.issueLableExpandModal}>Issue date</Typography>
                    <Typography
                     className={classes.issueNmaeExpandModal}>{this.state.certificateItem?.issue_date?.replace(/-/g, '/')}</Typography>
                     </Grid>
                <Grid item xs={6}>
                <Typography 
                className={classes.issueLableExpandModal}>Expiration date</Typography>
                    <Typography className={classes.issueNmaeExpandModal}>{this.state.certificateItem?.expiration_date?.replace(/-/g, '/')}</Typography>
                   </Grid>
                <Grid item xs={12}>
                <Typography 
                className={classes.issueLableExpandModal}>Duration</Typography>
                    <Typography className={classes.issueNmaeExpandModal}>{this.state.certificateItem?.expiration_date ? this.calculateMonthDuration(this.state.certificateItem?.issue_date,this.state.certificateItem?.expiration_date): "--"} Months</Typography>
                  </Grid>
             </Grid>
             </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={this.openEditCertificateModal} data-testId='expandEditBtn' className={classes.noBtnExpandModal}>Edit</Button>
            <Button className={classes.deleteBtnExpandModal} type="button" data-testId='expandDeleteBtn' onClick={this.openDeleteCertificationModal} >
                Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* Expand Modal End Here */}

        {/* Delete Modal Start Here */}
        <Dialog className={classes.deletepopupDeleteModal}
          open={this.state.visible}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDeleteCertificationClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography variant='h2' align='center' className={classes.popuptitleDeleteModal}>Are you sure?</Typography>

            <DialogContentText id="alert-dialog-slide-description">
              <Typography align="center" className={classes.popupdetailDeleteModal}>After deleting record, you won't be able
                to retrieve this information
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>
            <Button onClick={this.handleDeleteCertificationClose} data-testId='noDeleteBtn' className={classes.noButtonDeleteModal}>No</Button>
            <Button className={classes.deleteButtonDeleteModal} type="button" data-testId='DeleteBtn' onClick={() => this.deleteCertificateData(this.state.certificateItem?.id)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete Modal End Here */}

        {/* Edit Modal Start Here */}
        <Dialog
          open={this.state.show}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleEditcertificateModalClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.popuptitleAddModal} id="alert-dialog-slide-title">
            Edit Certificate
            <Button type="button" onClick={this.handleEditcertificateModalClose}>
              <img src={crossicon} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Name</InputLabel>
                  <TextField name='name' data-testId="editName" value={this.state.editCertificateForm.name} onChange={(e) => this.handleEditFormFields(e)} className={classes.certifyTextField1AddModal} placeholder='Ex:Salesforce Management' variant="outlined" />
                  {this.state.editCertificateErrors.name && <div style={{ color: 'red' }}>Please enter name</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Issuing Organization</InputLabel>
                  <TextField name='issuing_organization' data-testId="editOrgName" value={this.state.editCertificateForm.issuing_organization} onChange={(e) => this.handleEditFormFields(e)} className={classes.certifyTextField1AddModal} placeholder='Ex:Salesforce' variant="outlined" />
                  {this.state.editCertificateErrors.issuing_organization && <div style={{ color: 'red' }}>Please enter organisation name</div>}
                </Grid>
                <Grid item xs={12}>
                  <label className={classes.cerInpLabelAddModal}>Issue Date</label>
                  <DatePicker
                    className={classes.dateFieldsAddModal}
                    placeholderText='Issue Date'
                    value={this.state.issueDate}
                    required
                    data-testId="editIssueDate"
                    dateFormat="dd-MM-yyyy"
                    onChange={(date: any) => this.handleIssueDateFields(date, true)} />
                  {this.state.editCertificateErrors.issue_date && <div style={{ color: 'red' }}>Please select date</div>}
                </Grid>
                <Grid item xs={12}>
                  <label className={classes.cerInpLabelAddModal}>Expiration date</label>
                  <DatePicker
                    className={classes.dateFieldsAddModal}
                    placeholderText='Expiration Date'
                    value={this.state.expDate}
                    required
                    data-testId="editExpDate"
                    dateFormat="dd-MM-yyyy"
                    minDate={this.state.minDateExp}
                    onChange={(date: any) => this.handleExpDateFields(date, true)} />
                  {this.state.editCertificateErrors.expiration_date && <div style={{ color: 'red' }}>Please select date</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Credential ID</InputLabel>
                  <TextField name='credential_id' data-testId="editCredId" value={this.state.editCertificateForm.credential_id} onChange={(e) => this.handleEditFormFields(e)} id="outlined-basic1" className={classes.certifyTextField1AddModal} variant="outlined" />
                  {this.state.editCertificateErrors.credential_id && <div style={{ color: 'red' }}>Please enter Credential ID</div>}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className={classes.cerInpLabelAddModal}>Credential URL</InputLabel>
                  <TextField name='credential_url' data-testId="editCredUrl" value={this.state.editCertificateForm.credential_url} onChange={(e) => this.handleEditFormFields(e)} id="outlined-basic2" className={classes.certifyTextField1AddModal} variant="outlined" />
                  {this.state.editCertificateErrors.credential_url && <div style={{ color: 'red' }}>Please enter Credential URL</div>}
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ color: "#fff", background: "#2966FF", textTransform: "none" }} className={classes.submitButtonAddModal} type="button" data-testId="editSubmitButton" onClick={() => this.editCertificateData()}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit Modal End Here */}

        {/* Add Bank Modal Start Here */}
        <Dialog open={this.state.Isopen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle className={classes.ceripopuptitleAddBankModal} style={{ textAlign: 'center' }} id="form-dialog-title">Add Bank Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Bank</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} data-testid="addBankName"  name="bankName" onChange={(e: any) => this.handleAddBankAccoutDataFormFields(e)} placeholder='Wells Fargo' variant="outlined" />
                {this.showErrorMsg(this.state.addAccountDataErr.bankName, "Bank name should not empty.")}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Account Number</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} data-testid="addAccNo" name="accNo" onChange={(e: any) => this.handleAddBankAccoutDataFormFields(e)} placeholder='4517894615' variant="outlined" />
                {this.showErrorMsg(this.state.addAccountDataErr.accNo, "Account number should be 12 digits only.")}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Account Holder Name</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} data-testid="addAccHolderName" name="accHolderName" onChange={(e: any) => this.handleAddBankAccoutDataFormFields(e)} placeholder='John Doe' variant="outlined" />
                {this.showErrorMsg(this.state.addAccountDataErr.accHolderName, "Account holder name should not empty.")}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Routing Number</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} data-testid="addRouting" name="routingNo" onChange={(e: any) => this.handleAddBankAccoutDataFormFields(e)} placeholder='021548621' variant="outlined" />
                {this.showErrorMsg(this.state.addAccountDataErr.routingNo, "Routing number should be 9 digits only.")}
              </Grid>
            </Grid>
            <div style={{ color: 'red' }}>{this.state.addCardCustomErrMsg}</div>
          </DialogContent>
          <DialogActions className={classes.dialogActionsAddBankModal} >
            <Button className={classes.cancelBtnAddBankModal} style={{ textTransform: 'capitalize' }} data-testid="cancelAddBankData" onClick={this.handleDialogClose} variant="outlined">
              Cancel
            </Button>
            <Button className={classes.addBtnAddBankModal} style={{ textTransform: 'capitalize' }} data-testid="addBankData" onClick={() => this.addBankAccountData()} variant="contained" >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add Bank Modal End Here */}

        {/* Delete Bank Modal Start Here  */}
        <Dialog className={classes.deletepopupDeleteBankModal}
          open={this.state.Isdisplay}
          TransitionComponent={Transition}
          keepMounted

          onClose={this.handleDeleteBankClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
          <Typography variant='h2' align='center' className={classes.deletepopuptileDeleteBankModal}>Are you sure?</Typography>

            <DialogContentText id="alert-dialog-slide-description">
                <Typography  align="center" className={classes.deletepopupdetailDeleteBankModal}>Are you sure want to delete your account <strong>*****{this.state.bankAccountInfo.last_4_digit_acount_number}</strong>
                with <strong>{this.state.bankAccountInfo.bank_name}</strong> with Account Holder Name 
               <strong> {this.state.bankAccountInfo.account_name}</strong> and Routing Number <strong> {this.state.bankAccountInfo.routing_number}? </strong>
                 </Typography>
             </DialogContentText>
          </DialogContent>
          <DialogActions style={{display: 'flex', justifyContent: 'end'}}>
            <Button className={classes.noBtnDeleteBankModal} onClick={this.handleDeleteBankClose}>No</Button>
            <Button className={classes.deleteBtnDeleteBankModal}
             type="button" data-testid="deleteBankData" onClick={() => this.deleteBankAccountData(this.state.bankAccountInfo.bank_detail_id)}>
                Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* DeleteBank Modal End Here */}

        {/* Edit Bank Modal Start Here */}
        <Dialog open={this.state.display} onClose={this.handleEditBankClose} aria-labelledby="form-dialog-title">
          <DialogTitle className={classes.ceripopuptitleAddBankModal} style={{ textAlign: 'center' }} id="form-dialog-title">Edit Bank Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Bank</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} name="bankName"  disabled value={this.state.bankAccountInfo.bank_name} placeholder='Wells Fargo' variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Account Number</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} name="accNo" disabled value={`********${this.state.bankAccountInfo.last_4_digit_acount_number}`} placeholder='4517894615' variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Account Holder Name</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} name="accHolderName" data-testid="editAccHolderName" onChange={(e) => this.handleUpdatedAccountName(e)} value={this.state.bankAccountInfo.account_name} placeholder='John Doe' variant="outlined" />
                {this.showErrorMsg(this.state.editAccountErr.accHolderName, "Account Holder Name should not empty")}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.certifyInputLabelAddBankModal}>Routing Number</InputLabel>
                <TextField className={classes.certifyTextField1AddBankModal} name="routingNo" disabled value={this.state.bankAccountInfo.routing_number} placeholder='021548621' variant="outlined" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActionsAddBankModal} >
          <Button className={classes.cancelBtnAddBankModal} style={{ textTransform: 'capitalize' }} data-testid="cancelEditBankData" onClick={this.handleEditBankClose} variant="outlined">
              Cancel
            </Button>
            <Button className={classes.addBtnAddBankModal} style={{ textTransform: 'capitalize' }} data-testid='editBankData' onClick={() => this.editBankAccountData()} variant="contained" >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit Bank Modal End Here */}

      </ThemeProvider>
    );
  }
}

// Customizable Area End
// Customizable Area Start
export { UserProfile }
export default withStyles(ProfileStyle)(UserProfile);
// Customizable Area End
