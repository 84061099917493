// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Popover,
  MenuItem,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { createTheme, ThemeProvider, Theme, withStyles } from "@material-ui/core/styles";
import { cameraicon } from './assets'
import Header from "../../emailnotifications2/src/TopHeader.web";
import Loader from "../../../components/src/Loader.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CountryCodesInterface } from "./CustomisableUserProfilesController";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
export const EditStyle: any = (theme: Theme) => ({

    addBtn: {
        border: '1px solid #2966FF',
        display: "flex",
        width: "168px",
        color: '#FFF',
        height: "54px",
        background: '#2966FF',
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: '8px',
    },
    dialogActions: {
        display: 'flex',
        gap: 16,
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '56px 0 0',
    },
    cancelBtn: {
        display: "flex",
        height: "54px",
        width: "168px",
        borderRadius: '8px',
        padding: "10px 16px",
        justifyContent: "center",
        color: '#2966FF',
        alignItems: "center",
        background: '#FFF',
        border: '1px solid #2966FF',
    },
   
    fieldLabel: {
        color: '#0F172A',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        paddingBottom: 5,
    },
    fieldStyle: {
        width: '100%',
        height: 56,
        '& fieldset': {
        border: '1px solid #CBD5E1',
        borderRadius: 8, 
        },
        '& .Mui-focused': {
        '& fieldset': {
            borderColor: '#2966FF !important',
        },
        },
    },
    profileCard: {
        margin: 'auto',
        background: 'white',
        borderRadius: '15px',
        marginTop: 30,
        maxWidth: 967,
        padding: 25,
    },
    profilePic: {
        position:"absolute",
        height: 132,
        width: 132,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: '5px solid #2966FF',
        '& img': {
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        },
    },
    profilePicContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: 'max-content',
        paddingRight: '12px',
    },
    ProfilePicTxt: {
        position: 'relative',
        height: 140,
        width: 140,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        color: '#fff',
        '& h6': {
            fontSize: 14,
            margin: '5px 0',
            fontWeight: 700,
        },
    },
    passwordSec: {
        '& .MuiInputAdornment-root': {
        position: 'absolute',
        right: 14,
        top: 24,
        }
    },
    container:{
        background: '#f8fafc',
        padding: '0 25px 30px 25px',
    },
    allTextField: {
      padding: "0 15px", 
      borderLeft: '1px solid #DBDBDB',
      "@media(max-width: 967px)":
      { 
        borderLeft: 'none'
      }
    }
})

import ProfileController, {
  Props,
} from "./ProfileController.web";

class UserEditProfile extends ProfileController {
  constructor(props: Props) {
    super(props);   
  }
  renderCountryTextField = (params: any) => {
    return (<TextField {...params} variant="outlined" style={{padding:0}} placeholder="Please Select country"/>)
  }
  renderCityTextField = (params: any) => {
    return (<TextField {...params} variant="outlined" style={{padding:0}} placeholder="Please Select City"/>)
  }

  countryPHoneCodeDropdown() {
    return (
      <InputAdornment style={{
        marginRight: "0px",
        paddingLeft: "-8px"
      }} position="start">
        <Box
          style={{
            color: "#2966FF",
            marginRight: "0px",
            paddingLeft: "-8px",
          }}
        >
          <div onClick={(e) => this.setState({ anchorElePopOverNew: e.currentTarget, 
            openPopOverNew: true })} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
            <p>{this.state.selectedCountryCodeNew} </p>
            <KeyboardArrowDownIcon style={{ marginTop: "13px", 
             color: "rgba(0, 0, 0, 0.54)"
              }} />
          </div>

          <Popover
            open={this.state.openPopOverNew}
            anchorEl={this.state.anchorElePopOverNew}
            onClose={() => this.setState({ anchorElePopOverNew: null, openPopOverNew: false })}
            style={{
              maxHeight: "250px",
              maxWidth: "100px",
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >

            {this.state.countryCodesDataNew.map((item: CountryCodesInterface) => {
              return (
                <MenuItem onClick={this.handleCountryChange}
                  value={item.attributes.country_code}
                  style={{ width: "80px", cursor: "pointer", padding: "5px" }} >
                  +{item.attributes.country_code}
                </MenuItem>
              )
            })}

          </Popover>
        </Box>
      </InputAdornment>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Header />
        {this.state.pageLoader && <Loader loading={true} />}
        <Box className={classes.container}>
          <div className={classes.profileCard}>
            <Grid container spacing = {2}>
              <Grid item style={{ padding: 0 }} md={2} sm={12}>
              <Box
                className={classes.profilePicContainer}
                // style={{ height: '100%' }}
              >
                <label htmlFor="profilePicInput">
                  <Box className={classes.profilePic}>
                    <img src={this.state.profilePic} className={classes.profileimg} alt="Profile" />
                  </Box>
                  <Box
                    className={classes.ProfilePicTxt}
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <img src={cameraicon} className={classes.cameraIcon} alt="Camera" />
                    <Typography variant="subtitle1">Edit Picture</Typography>
                  </Box>
                </label>
                <input
                  id="profilePicInput"
                  type="file"                  
                  style={{ display: 'none' }}
                  onChange={this.handleImageChange}
                />
              </Box>
              </Grid>
                
              <Grid item className={classes.allTextField} md= {10} sm={12}>
                <Grid container spacing = {1}>
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel className = {classes.fieldLabel}>First Name</InputLabel>
                    <TextField size="medium" onChange={this.onFieldChange} name="first_name" value={this.state.userData.first_name} className={classes.fieldStyle} placeholder='First name' variant="outlined" />
                  </Grid>
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel className={classes.fieldLabel}>Last Name</InputLabel>
                    <TextField onChange={this.onFieldChange} name="last_name" value={this.state.userData.last_name} className={classes.fieldStyle} placeholder='Last Name' variant="outlined" />
                  </Grid>
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel className={classes.fieldLabel}>Email</InputLabel>
                    <TextField disabled name="email" value={this.state.userData.email} className={classes.fieldStyle} placeholder='Email' variant="outlined" />
                  </Grid>
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel className={classes.fieldLabel}>Password</InputLabel>
                    <Box className={classes.passwordSec} style={{ position: 'relative' as const}}>
                    <TextField 
                        type={this.state.updatePasswordShow? 'text': 'password'} 
                        autoComplete="off"
                          onChange={this.onPasswordUpdateChange}
                          id="update-new-password" 
                          className={classes.fieldStyle}
                          placeholder='********'
                          variant="outlined"  
                          error={!!this.state.passwordError}
                          helperText={this.state.passwordError}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">
                            <IconButton onClick={this.onShowPasswordIconClick} edge="end" style={{ fontSize: "small" }}> 
                              {(this.state.updatePasswordShow)?<VisibilityOutlinedIcon /> :<VisibilityOffOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                          }}
                        />
                        {
                          this.state.passwordError ? <div style={{ color: 'red' }}>Password should be a minimum of 8 characters long, contain both uppercase and lowercase characters, at least one digit, and one special character.</div> : ""
                        }
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel className = {classes.fieldLabel}>Phone</InputLabel>
                         <TextField
                      InputProps={{ startAdornment: this.countryPHoneCodeDropdown()}}
                      onChange={this.onFieldChange}
                      value={this.state.userData.phone_number}
                      name="phone_number"
                      className = {classes.fieldStyle}
                      data-test-id="txtInputLastName"
                      placeholder="8488xxxxxx"
                      id="outlined-start-adornment"
                      variant="outlined"
                      />
                  </Grid>      
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel htmlFor="Country-field"  className = {classes.fieldLabel}>Country</InputLabel>
                    <Autocomplete id="Country-field" className="country-select-container" style={{ height: 46, }} options={this.state.countryData} getOptionLabel={(option: any) => option.name} data-testid="country" value={this.state.selectedCountry} onChange={this.onCountryChange} renderInput={this.renderCountryTextField} disableClearable={true} />
                  </Grid>  
                  <Grid item md={6} sm={12} style={{ padding: 12}}>
                    <InputLabel htmlFor="City-field" className = {classes.fieldLabel}>City</InputLabel>
                    <Autocomplete id="City-field" className="country-select-container" style={{ height: 46 }} options={this.state.cityData} getOptionLabel={(option: any) => option.name} data-testid="city" value={this.state.selectedcity} onChange={this.onCityChange} renderInput={this.renderCityTextField} disableClearable={true} />
                  </Grid>             
                
                  </Grid>                  
                  <Grid item md={12} sm={12}>
                    <Box className= {classes.dialogActions}>
                      <Button className= {classes.cancelBtn} data-testId="cancelBtn" style={{ textTransform: 'capitalize' }} onClick={() => this.props.navigation.navigate("UserProfile")} variant="outlined">
                        Cancel
                      </Button>
                      <Button className= {classes.addBtn} style={{ textTransform: 'capitalize' }} onClick={this.updatedProfile} variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}

export { UserEditProfile }
export default withStyles(EditStyle)(UserEditProfile);
// Customizable Area End
