import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Country, City } from 'country-state-city';
import { profileLogo} from './assets';
import { apiCalling } from "../../../components/src/assets";
import { cropImage } from "../../emailnotifications2/src/assets";
type MonthlyData = (string | number)[];
interface ChartOptions {
  chart: {
    title: string;
    subtitle?: string;
    is3D?: boolean;
  };
}
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string
}
export interface SortHeaders {
  customer : boolean;
  contractor : boolean;
  "engagement_request" : boolean;
  "payment_status": boolean;
  "service_start_time" : boolean;
  "duration" : boolean;
  "platform" : boolean;
  "total_cost" : boolean;
}
interface PieChartOptions {
  title: string;
  is3D?: boolean;
}

interface DashboardData {
  available: boolean;
  lifetime: {
    total: number;
    month: number;
  };
  total_engagement: {
    total: number;
    week: number;
  };
  pending_earning: {
    total: number;
    month: number;
  };
  reviews: {
    star: {
      one: number;
      two: number;
      three: number;
      four: number;
      five: number;
    },
    rating: number;
    professionalism: number;
    efficiency: number;
    likelihood: number;
  }
}


export const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
];

interface Skilldata {
  data: [
    {
      id: string,
      type: string,
      attributes: {
        name: string,
        checked: boolean,
        sub_skills: {
          data: [
            {
              id: string,
              type: string,
              attributes: {
                name: string,
                checked: boolean
              }
            }
          ]
        }
      }
    }
  ]
}

interface SkillAttribute {
  id: string;
  type: string;
  attributes: {
    name: string;
    checked: boolean
  }
}

interface CountryList {
  name: string;
  isoCode: string
}

interface CityData {
name: string;
countryCode: string,
latitude: string,
longitude: string,
stateCode: string
}

interface EngagementRecordData {
  id: string;
  type: string;
  attributes: {
    room_id: string | null;
    id: number;
    payment_status: string;
    platform_id: number;
    service_end_time: string | null;
    service_start_time: string;
    engagement_request: string;
    duration: string | null;
    total_cost: number;
    platform: string;
    customer: {
      data: {
        type: string;
        id: string;
        attributes: {
          [key: string]: boolean | number | string | null; 
          photo: string | null;
          activated: boolean;
          last_name: string;
          first_name: string;
          type: string;
          phone_number: number;
          country: string | null;
          email: string;
          city: string | null;
          rating: number;
          verified: boolean;
        };
      };
    };
    contractor: {
      data: {
        type: string;
        id: string;
        attributes: {
          email: string;
          year_of_experience: number;
          photo: string | null;
          verified: boolean;
          phone_number: number;
          type: string;
          city: string | null;
          country: string | null;
          activated: boolean;
          rating: number;
          last_name: string;
          first_name: string;
          is_available: boolean;
          contractor_price: number;
          [key: string]: boolean | number | string | null | {}[]; // Updated index signature
          skills: {
            subskills: string[];
            name: string;
          }[];
        };
      };
    };
  };
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history?: any;
  location?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  companydata: any;
  checkedC: boolean;
  data: any[];
  options: ChartOptions;
  piechartData: MonthlyData[];
  piechartOptions: PieChartOptions;
  engageMentRecords: any;
  accountData: any;
  showEngagementModal: boolean;
  engagementModalType: string;
  pageLoading: boolean;
  engagementData: any;
  loader: boolean;
  ratingModalType: string;
  showRatingModal: boolean;
  alertMsgType: any;
  snackbarOpen: boolean;
  alertMsg: string;
  contractorDashboardData: any;
  openModal: boolean;
  personName: any;
  country: Array<Object>;
  city: string
  skillList: Skilldata
  countryList: Array<CountryList>;
  citiesList: any;
  mainID: string;
  isMainCheck: boolean;
  countryName: string,
  cityName: string;
  SkillData: Array<SkillAttribute>;
  availableStatus:boolean;
  subDomainData: any;
  subDomainIds: any;
  subSkillsDefaultValue: any;
  pageLoader: boolean;
  selectedcity: any;
  selectedCountry: any;
  contractor_price: any;
  sortHeader: SortHeaders;
  sortingOrder: string;
  earningYear: number;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apiCompanyItem: string = " ";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAccountDataId: string = "";
  ratingSubmitAPiID: string = "";
  getEngageMentDataApiCallId: string = "";
  contractorDashboardCall: string = "";
  skillDataApiCallId: string = "";
  availabilityFomeAPiCallId: string = "";
  availabilityStatusUpdate: string = "";
  getMyEarningApiCallId: string ="";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      contractorDashboardData: "",
      companydata: [],
      errorMsg: "",
      token: "",
      loading: false,
      checkedC: false,
      availableStatus:false,
      data: [
        ["Month", "", { role: "style" }],
        ["Jan", 0,"white"],
        ["Feb", 0,"white"],
        ["Mar", 0,"white"],
        ["Apr", 0,"white"],
        ["May", 0,"white"],
        ["Jun", 0,"white"],
        ["Jul", 0,"white"],
        ["Aug", 0,"white"],
        ["Sep", 0,"white"],
        ["Oct", 0,"white"],
        ["Nov", 0,"white"],
        ["Dec", 0,"white"],
      ],
      options: {
        chart: {
          title: "My Earnings",
          subtitle: "",
        },
      },
      piechartData: [
        ["Task", "Hours per Day"],
        ["Professionalism 85%", 30],
        ["Likelihood 75%", 8],
        ["Efficiency 60%", 4],
      ],
      piechartOptions: {
        title: "",
        is3D: true,
      },
      engageMentRecords: {},
      showEngagementModal: false,
      engagementModalType: "",
      accountData: null,
      engagementData: [],
      loader: true,
      pageLoading: true,
      ratingModalType: "",
      showRatingModal: false,
      alertMsgType: 'error',
      snackbarOpen: false,
      alertMsg: '',
      openModal: false,
      personName: [],
      country: [],
      city: "",
      skillList: {
        data: [{
          id: "",
          type: "",
          attributes: {
            name: "",
            checked: false,
            sub_skills: {
              data: [
                {
                  id: "",
                  type: "",
                  attributes: {
                    name: "",
                    checked: false
                  }
                }
              ]
            }
          }
        }]
      },
      countryList: [{
        name:'india',
        isoCode: '455001'
      }],
      citiesList: [{
        countryCode: "AG"
      ,
        latitude: "17.06671000"
      ,
        longitude: "-61.79303000"
      ,
        name: "All Saints"
      ,
        stateCode: "07"
      }],
      mainID: "",
      isMainCheck: false,
      countryName: "",
      cityName: "",
      SkillData: [{
        id: "",
        type: "",
        attributes: {
          name: "",
          checked: false
        }
      }],
      subDomainData: [],
      subDomainIds: "",
      subSkillsDefaultValue: [],
      pageLoader: true,
      selectedcity: "",
      selectedCountry: "",
      contractor_price: "",
      sortHeader: {
        customer: false,
        contractor: false,
        "engagement_request": false,
        "payment_status": false,
        "service_start_time": false,
        "duration": false,
        "platform": false,
        "total_cost": false,
      },
      sortingOrder: "ascending",
      earningYear: new Date().getFullYear(),
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if(window.location.pathname == "/company"){
      this.getCompanyData();
    }else{      
      this.getSkill();
      this.isAuthenticated();
      this.getEngagementData();
      this.getMyEarningData();
      this.initializeFirebaseMessaging();
      this.getBroadcastMessage();
      if (this.props.location.state?.modalType) {
        const { modalType } = this.props.location.state;
        this.setState({ ratingModalType: modalType, showRatingModal: true });
      }
      this.setState({
        countryList: Country.getAllCountries()
      })
      setTimeout(() => this.getOverall(), 1000)
    }
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const restApiResponseMessageName = getName(MessageEnum.RestAPIResponceMessage);
    const restApiResponseDataMessageName = getName(MessageEnum.RestAPIResponceDataMessage);
    const restApiResponseSuccessMessageName = getName(MessageEnum.RestAPIResponceSuccessMessage);


    if (restApiResponseMessageName === message.id) {
      const apiRequestCallId = message.getData(restApiResponseDataMessageName);
      const responseJson = message.getData(restApiResponseSuccessMessageName);
      if(responseJson.errors){
        this.checkErrors(apiRequestCallId, responseJson)
      }
      if (responseJson || responseJson.data) {
        this.contractorDashboardApiCall(apiRequestCallId, responseJson)
        this.modalSkillApiCall(apiRequestCallId, responseJson)
      }
      if (responseJson && responseJson.data) {
        this.handleEngageMentApiCall(apiRequestCallId, responseJson)
      } else if (responseJson || responseJson.data) {
        this.contractorDashboardApiCall(apiRequestCallId, responseJson)
        this.sucessCallBackFunction(apiRequestCallId, responseJson)
      } else {
        this.setState({
          alertMsgType: 'error',
          showRatingModal: false
        }, () => {
          this.props.history.replace("contractorDashboard")
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkErrors = (apiRequestCallId: string, responseJson: any) => {
    if(responseJson.errors){
      if(apiRequestCallId === this.availabilityStatusUpdate){   
        if(responseJson.errors && responseJson.errors[0]?.account && responseJson.errors[0]?.account ==="Account Not Found"){
          this.props.history?.push("")
        }    
        this.setState({         
          pageLoader: false, 
          snackbarOpen: true, 
          alertMsgType: 'error',
          alertMsg: responseJson.errors[0].sub_skills? responseJson.errors[0].sub_skills: "Something went Wrong"
        }); 
      }         
    }
  }
  sucessCallBackFunction=(apiRequestCallId: string, responseJson: any)=>{
    if (apiRequestCallId === this.getAccountDataId) {
      this.setState({
        accountData: responseJson.data
      })
    }
    if (apiRequestCallId === this.apiCompanyItem) {     
      this.setState({ companydata: responseJson.data });
    }
    if (apiRequestCallId === this.ratingSubmitAPiID) {
      this.setState({
        snackbarOpen: true,
        alertMsgType: 'success',
        alertMsg: 'Thanks for your feedback!',
        showRatingModal: false,
      }, () => {
        this.props.history?.replace("contractorDashboard")
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.earningYear !== this.state.earningYear) {
         this.getMyEarningData()
    }
  }
  
  closeAlertMsg = () => {
    this.setState({
      snackbarOpen: false
    })
  }
  closeRatingModal = () => {
    this.setState({
      showRatingModal: false
    })
    this.props.history.replace("contractorDashboard")
  }
  initializeFirebaseMessaging = async () => {
    
  };
  getBroadcastMessage = () => {
    
  }
  onNotificationMessage = (payload: any) => {
    const data = payload.data;
    let userEngData, photo = "";
    if (data["photo"]) {
      photo = data["photo"]
    }
    if (data["customer"]) {
      userEngData = JSON.parse(data["customer"]);
    }
    const engagementData = {
      meeting_id: data["meeting_id"],
      platform_name: data["platform_name"],
      videosdk_token: data["videosdk_token"],
      body: data["message"],
      message: data['message'],
      customer: userEngData,
      photo: photo
    }
    if (engagementData?.meeting_id) {
      this.setState({
        engageMentRecords: engagementData,
        showEngagementModal: true,
        engagementModalType: "engagmentRequest",
      })
    }
  }
  getTokenValue = () => {
    const token = localStorage.getItem('signin-token');
    return token;
  }
  isAuthenticated = async () => {
    const token = this.getTokenValue();
    if (token) {
      this.setState({ token: token }, () => {
        this.getDashboardData();
        this.getAccountData();
      })
    } else {
      this.props.navigation.navigate("Home")
    }
  }
  callDashBoardApi(props: any) {
    const getdashBoardApi = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getdashBoardApi.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), props.endPoint);
    getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(props.headers));
    if (props.body) {
      getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(props.body));
    }
    getdashBoardApi.addData(getName(MessageEnum.RestAPIRequestMethodMessage), props.requestType);
    runEngine.sendMessage(getdashBoardApi.id, getdashBoardApi);
    return getdashBoardApi.messageId;
  }

  getAccountData = () => {
    const headers = {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.getAccountDataId = this.callDashBoardApi({
      headers: headers,
      endPoint: "account",
      requestType: "GET"
    })
  }
  getCompanyData = () => {
    this.setState({ loading: true })

    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCompanyItem = requestMessage.messageId;
    this.setState({ loading: true });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };

  closeEngagementModal = () => {
    this.setState({
      showEngagementModal: false,
      engagementModalType: ""
    })
  }

  onAcceptEngagement = () => {
    this.setState({
      engagementModalType: 'rating'
    })
  }

  ratingSubmitAPi = (body: any) => {
    const headers = {
      'Content-Type': 'application/json',
      token: this.state.token
    }
    this.ratingSubmitAPiID = this.callDashBoardApi({
      headers: headers,
      body: body,
      endPoint: "reviews",
      requestType: "POST"
    })
  }

  handleEngageMentApiCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getEngageMentDataApiCallId) {
      this.setEngageMentDataResponse(responseJson)

    } else if (apiRequestCallId === this.availabilityFomeAPiCallId) {
      this.availabilityFomeAPiCallIdResponse(responseJson)
    }
    else if (apiRequestCallId === this.availabilityStatusUpdate) {
      this.availabilityStatusUpdateCallResponse(responseJson)
    }
    if (apiRequestCallId === this.getAccountDataId) {
      const accountDataApiResponse = responseJson.data
      if(accountDataApiResponse && accountDataApiResponse.attributes.photo){
        accountDataApiResponse.attributes.photo = await cropImage(accountDataApiResponse.attributes.photo, false)
      }
      this.setState({
        accountData: responseJson.data
      })
    }
    if (apiRequestCallId === this.apiCompanyItem) {      
      const sortedExperts = responseJson.data.attributes.top_experts.data.sort((first: any, second: any) => second.attributes.rating - first.attributes.rating);
      responseJson.data.attributes.top_experts.data = sortedExperts;
      this.setState({ companydata: responseJson.data });
    }
    if (apiRequestCallId === this.ratingSubmitAPiID) {
      this.setState({
        snackbarOpen: true,
        alertMsgType: 'success',
        alertMsg: 'Thanks for your feedback!',
        showRatingModal: false,
      }, () => {
        this.props.history.replace("contractorDashboard")
      })
    }
  }

  availabilityStatusUpdateCallResponse = async (responseJson: any) => {
    if(responseJson.data){
      this.setState((prevState) => ({
        availableStatus: responseJson.data.attributes.is_available, 
        pageLoader: false
      }));
      this.getOverall();    
      localStorage.setItem("available", responseJson.data.attributes.is_available)  
    }
  }

  availabilityFomeAPiCallIdResponse = (responseJson: any) => {
    this.setState({ accountData: responseJson.data, openModal: false })
    this.onStatusUpdate();
  }

  getEngagementData = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: localStorage.getItem('signin-token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEngageMentDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEngagementDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setEngageMentDataResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ engagementData: responseJson?.data, loader: false })
    } else {
      this.setState({ engagementData: [], loader: false })
    }
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: localStorage.getItem('signin-token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData" ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  };

  contractorDashboardApiCall = (apiRequestCallId: string, responseJson: DashboardData) => {
    if(apiRequestCallId === this.contractorDashboardCall){
      let getLocalstorageAvailable = localStorage.getItem("available")
      
      if(getLocalstorageAvailable === "undefined"){
        const resAvailability: any = responseJson.available
        getLocalstorageAvailable = resAvailability
        localStorage.setItem("available", resAvailability)
      }
      this.setState({
        contractorDashboardData: responseJson,
        availableStatus: getLocalstorageAvailable == "true" ? true : false,
        pageLoader: false
      })

    } else if ( apiRequestCallId === this.getMyEarningApiCallId) {
        this.setMyEarningDataResponse(responseJson)
    }
        
  }

  getOverall = async () => {
    this.contractorDashboardCall = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.dashboarApi}?year=${new Date().getFullYear()}`
    });
  }

  handleNavigate = () => {
    this.props.navigation.navigate("EngagemenRequestList")
  };

  handleChangeList = (event:any) => {
    event.stopPropagation();
    this.setState({ personName: event.target.value });
  };
  modalOpenHandler = () => {
    this.setState({ openModal: true });
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };
  handleDelete = (value: any) => {
    const filteredList = this.state.personName.filter((chip: any) => chip !== value);
    const filteredListnew = this.state.SkillData.filter((chip: any) => {

      if (value !== chip?.attributes?.name) {
        return chip
      }
    });

    this.setState({ personName: filteredList, SkillData: filteredListnew });
  };
  handleCountryChange = (selectedData: React.ChangeEvent<HTMLInputElement> | any) => {    
    let countryCode = this.state.countryList.findIndex((item:any)=> item.name === selectedData.name)
    if(countryCode !== -1){
      let citiesList = City?.getCitiesOfCountry(this.state.countryList[countryCode].isoCode)
      this.setState({ 
        country: selectedData,
        selectedCountry: selectedData,
        countryName: selectedData.name,
        citiesList: citiesList,
        city: "",
        selectedcity: "",
        cityName: ""
      }); 
    }
  };
  handleCityChange = (selectData: any) => {
    this.setState({ cityName: selectData?.name, city: selectData, selectedcity: selectData  });
  };
  onContractorPriceChange = (event: any) => {
    if(event.target.value){
      this.setState({
        contractor_price: event.target.value
      })
    }    
  }

  getSkill = async () => {
    this.skillDataApiCallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: "GET",
      endPoint: "/skills"
    });
  }

  modalSkillApiCall = (apiRequestCallId: string, responseJson: Skilldata) => {
    if (apiRequestCallId === this.skillDataApiCallId) {       
      if(responseJson && responseJson.data) {
        const allSkills = responseJson.data
        const selectedSkill = allSkills.find((value: any) => value?.attributes.checked);
        const dataList = selectedSkill?.attributes?.sub_skills.data||[];         
        const subSkillsData = dataList.filter((val: any) => val.attributes.checked);        
        let subSkillIds: any = [], defaultSubSkills:any = [];       
        if(subSkillsData?.length >0) {
          subSkillsData.forEach((subSkillVal: any) => {
            subSkillIds.push({"sub_skill_id": subSkillVal.id,"checked": true });
            defaultSubSkills.push(subSkillVal);             
          })                
        } 
        this.setState({ 
          skillList: responseJson, 
          subDomainData: dataList, 
          mainID: selectedSkill?.id||"", 
          isMainCheck: selectedSkill?.attributes?.checked|| false, 
          subSkillsDefaultValue: defaultSubSkills,
          subDomainIds: subSkillIds
        })
      }
    }

  }

  changeSubDomainData = (newVal: any) => {
    const ids = newVal.map((item: any) => ({ "sub_skill_id": item.id, "checked": true }));
    this.setState({ subDomainIds : ids})
  }

  onPressSignInCallID = async () => {     
    const subSkillsArr: number[] = [];
    this.state.subDomainIds.map((val: any) => {
      subSkillsArr.push(Number(val.sub_skill_id))
    });     
 
    const anyFieldEmpty = subSkillsArr.length > 0 && this.checkValueisNotEmptyorUndefined(this.state.cityName) && this.checkValueisNotEmptyorUndefined(this.state.countryName) && this.state.contractor_price
    if(!anyFieldEmpty){
      this.setState({         
        pageLoader: false, 
        openModal: false,
        snackbarOpen: true, 
        alertMsgType: 'error',
        alertMsg: `Fields Should not be Empty`
      });
      return null
    }

    let formdata = new FormData();
    formdata.append("data[attributes][country]", this.state.countryName)       
    formdata.append("data[attributes][city]", this.state.cityName)
    formdata.append("data[attributes][sub_skills]", JSON.stringify(subSkillsArr))
    formdata.append("data[attributes][hour_rate]",this.state.contractor_price);

    const header = {
      token: localStorage.getItem('signin-token')
   };
   const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    this.availabilityFomeAPiCallId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),"account");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"PUT");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),formdata);
    runEngine.sendMessage(requestMessage.id, requestMessage);     
  }

  cancelButtonCall = () => {
    this.setState({ openModal: false })
  }


  onStatusUpdate = async () => {
    let formdata = new FormData();
    formdata.append("data[attributes][is_available]", this.state.availableStatus ? "false" : "true" );
    this.availabilityStatusUpdate = await this.apiCall({
      method: 'PATCH',
      endPoint: '/account/change_availability_status',
      body: formdata,
      type: 'formData'
    });

  }


  getCityByCountry = (country: string) => {
    let cityArray: any[] = []
    const cities = City?.getCitiesOfCountry(country);
    if (cities) {
      cityArray = cities
    }
    return cityArray
  }

  getCitiesList = (allCountries: any, country: string) => {
    const countries = allCountries.find((obj:any) => obj.name === country)
    let region = [];
    if(countries !== undefined) {
      region = this.getCityByCountry(countries?.isoCode)
    }
    return region;
  }
  checkValueisNotEmptyorUndefined = (val: any) => {
    if(val && val!== "" && val !== undefined){
      return true
    }else {
      return false
    }
  }

  changeAvailability = async () => {
    const userData = this.state.accountData?.attributes;
    const subSkillsData = userData.sub_skills;
    if(this.state.availableStatus || (subSkillsData?.length > 0 && this.checkValueisNotEmptyorUndefined(userData.country) && this.checkValueisNotEmptyorUndefined(userData.city) && userData.contractor_price)){
      this.setState({
        pageLoader: true
      })
      this.onStatusUpdate();
    }else{
      this.updateUserDetailsForAvailability();
    } 
  }
  updateUserDetailsForAvailability = () => {
    const userData = this.state.accountData?.attributes;     
      let citiesList: any = [];
      let selectCountry: any = "";
      let selectCity: any = "";
      if(userData.country) {
        const allCountries = this.state.countryList;        
        selectCountry = allCountries.find((val: any) =>  val.name === userData.country)||{};
        citiesList = City.getCitiesOfCountry(selectCountry?.isoCode) || []; 
        if(userData.city){
          selectCity = citiesList.find((val: any) =>  val.name === userData.city);
        }    
      }
      this.setState({ 
        country: userData.country,
        countryName: userData.country, 
        cityName: userData.city || "", 
        citiesList: citiesList, 
        city: userData.city || "", 
        selectedCountry: selectCountry,
        selectedcity: selectCity, 
        contractor_price: userData.contractor_price,
        openModal: true
      })
  }
  redirectToEngDetailPage = (data: any) => {

     // Fullname
     let firstName: string = ""
     let lastName: string = ""
     if (data.attributes.customer.data && data.attributes.customer.data.attributes.first_name) {
         firstName = data.attributes.customer.data.attributes.first_name
     }
     if (data.attributes.customer.data && data.attributes.customer.data.attributes.last_name) {
         lastName = data.attributes.customer.data.attributes.last_name
     }
     const FullName = firstName + " " + lastName;

     let profileImg: string = profileLogo
     if (data.attributes.customer.data && data.attributes.customer.data.attributes.photo) {
         profileImg = data.attributes.customer.data.attributes.photo
     }

     let custRating: number = 0
     if (data.attributes.customer.data && data.attributes.customer.data.attributes.rating) {
         custRating = data.attributes.customer.data.attributes.rating
     }

    const requestListdata = {
      "dataList": [
        {
          "img": profileImg,
          "name": FullName,
          "durationTime": "-",
          "engagementRequestMsg": data.attributes.engagement_request,
          "paymentStatusList": {
            "title": "Pending",
            "backgroundColor": "rgba(255, 41, 41, 0.20)",
            "fontColor": "#DC2626"
          },
          "platformNameList": data.attributes.platform,
          "totalCostList": data.attributes.total_cost,
          "roomId": data.attributes.room_id,
          "contractorId" : data.attributes.contractor.data.id,
          "rating": custRating
        }
      ]
    }

    this.props.history.push({
      pathname: "/EngagemenRequest",
      state: {engageMentRecords : data, requestData : requestListdata},
    });
  }
  sortTableDataHandlerBlock = (sortBy: keyof SortHeaders, sortName: string, sortingOrder: string) => {

    const sortedData = this.state.engagementData.sort((a: any, b: any) => {
      
      let firstValue: string = '';
      let secondValue: string = ''; 

      switch (sortBy) {
        case "customer":
        case "contractor":
          firstValue = (a.attributes[sortBy].data.attributes[sortName])?.toLowerCase();
          secondValue = (b.attributes[sortBy].data.attributes[sortName])?.toLowerCase();
          break;
        case "payment_status":
        case "engagement_request":
        case "service_start_time":
        case "platform":
          firstValue = a.attributes[sortBy].toLowerCase();
          secondValue = b.attributes[sortBy].toLowerCase();
          break;
        case "total_cost":
          firstValue = a.attributes[sortBy].toString();
          secondValue = b.attributes[sortBy].toString();
          break;
        case "duration":
          firstValue = a.attributes[sortBy].toString().toLowerCase();
          secondValue = b.attributes[sortBy].toString().toLowerCase();
          break;    
      }

      switch (sortingOrder) {
        case "ascending":
          if (firstValue < secondValue) {
            return -1;
          }
          if (firstValue > secondValue) {
            return 1;
          }
          break;
        case "descending":
          if (firstValue > secondValue) {
            return -1;
          }
          if (firstValue < secondValue) {
            return 1;
          }
          break;
        default:
          return 0;
      }
  
    });

    const sortOrderHandle = this.sortHandle()

    const initialVal : SortHeaders = {
        contractor: false,
        customer: false,
        "engagement_request": false,
        "payment_status": false,
        "service_start_time": false,
        "duration": false,
        "platform": false,
        "total_cost": false,
    }

    initialVal[sortBy] = this.arrowIcon()

    this.setState({
      engagementData: sortedData,
      sortingOrder : sortOrderHandle,
      sortHeader: initialVal
    })
  }

   
  arrowIcon = () => {
     return this.state.sortingOrder === "ascending" ? false : true;
  }

  sortHandle = () => {
    return this.state.sortingOrder === "ascending" ? "descending" : "ascending"
  }

  getMyEarningData = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('signin-token')
    };

    const currentYear = this.state.earningYear;
    const contractorId = localStorage.getItem("userId");

    this.getMyEarningApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getMyEarningApiEndPoint}?year=${currentYear}&contractor_id=${contractorId}`,
      method: configJSON.httpGetMethod,
    })
  }

  handleEarningYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newVal = e.target.value as number;
    this.setState({ earningYear : newVal})
  }

  setMyEarningDataResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const originalData = responseJson.data
      const convertedData = [
        ["Month", "Earnings", { role: "style" }]
      ];

      for (const month in originalData) {
        if (originalData.hasOwnProperty(month)) {
          const value = originalData[month].value;
          const style = "#DBEAFE"; // This color is static according to your example
          convertedData.push([month, value, style]);
        }
      }
      this.setState({ data : convertedData})
    } else {
      const graphData =  [
        ["Month", "Earnings", { role: "style" }],
        ["Jan", 0,"white"],
        ["Feb", 0,"white"],
        ["Mar", 0,"white"],
        ["Apr", 0,"white"],
        ["May", 0,"white"],
        ["Jun", 0,"white"],
        ["Jul", 0,"white"],
        ["Aug", 0,"white"],
        ["Sep", 0,"white"],
        ["Oct", 0,"white"],
        ["Nov", 0,"white"],
        ["Dec", 0,"white"],
      ]

      this.setState({ data : graphData})
    }
  }

  
  

  // Customizable Area End

}
