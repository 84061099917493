import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Card,
  InputLabel,
  TextField,
  CardContent,
  styled,
  withStyles
} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { infoSvg, loaderSvg, halfStar, goldStar, blackStar, contractor } from "./assets";
import SearchContractorController, { Props } from "./searchContractorController.web";
// Customizable Area End


export default class SearchContractor extends SearchContractorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCreditCardForm = () => {
    return (
      <Box style={{ padding: '0px 33px' }}>
        {this.props.cardType === "contractorCard" ? <React.Fragment>
          <Typography variant="h5" gutterBottom component="div">Please add credit card Details</Typography>
          <Typography gutterBottom component="p">You will not be charged for your first engagment</Typography>
        </React.Fragment> : <Typography className="clr-blue txt-center" variant="h5" gutterBottom component="div">Add New Card</Typography>}
        <Box style={{ marginBottom: '24px' }}>
          <InputLabel id="card-holder-name">Card Holder name</InputLabel>
          <TextField
            variant="outlined"
            id="card-holder-name"
            placeholder="Enter Card holder name"
            style={{ marginTop: '15px', width: '100%' }}
            onChange={() => { }}
          // value={this.state.email}
          // error={!!this.state.errorLoginObj.emailError}
          // helperText={this.state.errorLoginObj.emailError}
          />
        </Box>
        <Box style={{ marginBottom: '24px' }}>
          <InputLabel id="card-holder-number">Card Holder Number</InputLabel>
          <TextField
            data-test-id="card-holder-number"
            variant="outlined"
            id="card-holder-number"
            placeholder="Enter Card holder Number"
            onChange={() => { }}
            style={{ marginTop: '15px', width: '100%' }}
          // value={this.state.email}
          // error={!!this.state.errorLoginObj.emailError}
          // helperText={this.state.errorLoginObj.emailError}
          />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Box style={{ width: '48%' }}>
            <InputLabel id="expiration-Dtae">Expiration Date</InputLabel>
            <TextField
              data-test-id="expiration-Dtae"
              variant="outlined"
              id="expiration-Dtae"
              placeholder="Enter Expiration Date"
              style={{ marginTop: '15px', width: '100%' }}
              onChange={() => { }}
            // value={this.state.email}
            // error={!!this.state.errorLoginObj.emailError}
            // helperText={this.state.errorLoginObj.emailError}
            />
          </Box>
          <Box style={{ width: '48%' }}>
            <InputLabel id="security-code">Security Code</InputLabel>
            <TextField
              data-test-id="security-code"
              variant="outlined"
              id="security-code"
              placeholder="Enter Security Code"
              onChange={() => { }}
              style={{ marginTop: '15px', width: '100%' }}
            // value={this.state.email}
            // error={!!this.state.errorLoginObj.emailError}
            // helperText={this.state.errorLoginObj.emailError}
            />
          </Box>
        </Box>
        <Box sx={webStyles.modalButtonView}>
          <Box>
            <Button variant="outlined" color="primary" onClick={this.props.closeModal} size="large" className="custom-button-padding custom-button-outlined">Cancel</Button>
          </Box>
          <Box>
            <Button variant="contained" size="large" color="primary" className="custom-button-padding custom-button-contained" onClick={() => { }}>Add</Button>
          </Box>
        </Box>
      </Box>
    )
  }
  rederModalLoader = () => {
    this.stratProgressing();
    const ProgressBar = () => {
      return (
        <Typography component="div" className="progressBarContainer">
          <Typography component="div" className="progressBar" style={{ width: `${this.state.progress}%` }} />
        </Typography>
      );
    };
    return (
      <div className="contractor-loader-container">
        <SiTText variant="h6" className="contractor-loader-header">Sit back while we find the best person to help you..</SiTText>
        <img className="contractor-loader-image" src={loaderSvg} alt="loader" />
        {ProgressBar()}
        <Typography variant="caption" className="contractor-loader-desc" component="p">Connecting with contractor in <span className="contractor-loader-desc-highlight">3:00</span> min or less</Typography>
      </div>
    )
  }
  renderContractors = () => {
    return (
      <div className="modal-contractor-container">
        <Typography variant="h5" className="modal-contractor-header" component="div">Choose your contractor</Typography>
        <div className="modal-contractor-cards-container">
          {this.props.contractorData.length > 0 ? this.props.contractorData.map((item: any, index: number) => {
            if(!item){
              return ""
            }
            return (
              <div className="">
                <Typography variant="h6" className="modal-contractor-cat-header" component="div">{item.type}</Typography>
                <Card id={`contractors-${index}`} className={this.state.highlightContractor === index?"card-highlight card" : "card"} data-testId={`index-${index}`} onClick={() => this.onContractorSelect(item.id, index)} variant="outlined" key={`contractors-${index}`}>
                  <img className="modal-contractor-cards-img"
                    src={item.attributes.photo || contractor}
                    alt={item.attributes.first_name} />
                  <div className="modal-contractor-rating"> <img src={halfStar} alt="Half-star" /> {item.attributes.rating ?? 0}</div>
                  <CardContent className="modal-contractor-cards-content">
                    <Typography className="contractor-name" gutterBottom variant="subtitle2">
                      {item.attributes.first_name} {item.attributes.last_name}
                    </Typography>
                    <Typography gutterBottom variant="caption" component="p">
                      {item.address}
                    </Typography>
                    <Typography className="modal-contractor-cards-charge" variant="caption" component="div">
                      ${item.attributes.contractor_price}/hr
                      <span className="modal-contractor-card-info">
                        <CustomTooltip className="modal-contractor-card-tooltip" title={
                          <React.Fragment>
                            <div className={`contractor-charge-break-container ${this.state.highlightContractor === index ? "": "d-none"}`}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="fee-container" gutterBottom variant="caption" component="span">
                                  Contractor Fee
                                </Typography>
                                <span>{this.truthyValue(this.state.collectFeesData.ContractorFee)}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="fee-container" gutterBottom variant="caption" component="span">
                                  Connection Fee
                                </Typography>
                                <span>{this.truthyValue(this.state.collectFeesData.Description).split(":")[1].trim()}</span>
                              </div>

                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="fee-container" gutterBottom variant="caption" component="span">
                                  Service Fee(for 8hrs)
                                </Typography>
                                <span>{this.truthyValue(this.state.collectFeesData.ServiceFee)}</span>
                              </div>

                              <hr className="clr-gray" />
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className="fee-container" gutterBottom variant="caption" component="span">
                                  Total Cost
                                </Typography>
                                <span className="clr-blue txt-bold"> {this.truthyValue(this.state.collectFeesData.TotalCost)}</span>
                              </div>
                            </div>
                          </React.Fragment>
                        } placement="bottom">
                          <img className="info-icon" src={infoSvg} />
                        </CustomTooltip>
                      </span>
                    </Typography>

                  </CardContent>
                </Card>
              </div>
            )
          }) : "No Contractors Found"}
        </div>
        <Box sx={webStyles.modalButtonView}>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.props.closeModal}
              size="large"
              className="custom-button-padding custom-button-outlined"
            >
              Deny
            </Button>
          </Box>
          <Box>
            <Button
              data-test-id="acceptContractor"
              variant="contained"
              size="large"
              color="primary"
              className="custom-button-padding custom-button-contained"
              onClick={() => this.onAcceptContractor()}
              disabled={this.state.selectedContractorId ? false : true}
            >
              Accept
            </Button>
          </Box>
        </Box>

      </div>
    )
  }
  renderRatingContractor = () => {
    return (
      <Box className="rate-contractor-container">

        <RateText variant="h6" className="rate-contractor-header">Please rate your {this.props.dashboard ? "customer" : "contractor"}</RateText>
        <RateTextLine variant="subtitle2" className="rate-contractor-sub-header">Share Your Experience</RateTextLine>
        <Box className="rate-contractor-content">
          {this.state.ratingCat.map((cat: any, index: number) => {

            return (
              <Box key={`cat-${index}`} className="star-category-container">
                <Typography variant="subtitle2" style={{ color: '#64748B' }}>{cat.title}</Typography>
                <Box style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  {[1, 2, 3, 4, 5].map((value: number, starIndex: number) => (<img onClick={() => this.onStarClick(index, value)} key={`stars-${index}-${starIndex}`} style={{ color: '#DDC026', height: 36, width: 36, cursor: 'pointer' }} src={(value <= cat.value) ? goldStar : blackStar} alt="Star" />))}
                </Box>

              </Box>
            )
          })}

          <Box className="rating-field">
            <InputLabel style={{ textAlign: 'left' }} id="anyThing-else">Anything else?</InputLabel>
            <TextField
              data-test-id="card-holder-name"
              style={{ marginTop: '15px', width: '100%' }}
              value={this.state.anythingElse}
              onChange={(event: any) => this.onFieldChange(event)}
              id="anyThing-else"
              variant="outlined"
              placeholder="Share with us here...."

            />
          </Box>
        </Box>
        <Box>

          <Box sx={webStyles.modalButtonView}>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.closeModal}
                size="large"
                className="custom-button-padding custom-button-outlined"
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className="custom-button-padding custom-button-contained"
                onClick={this.onRatingSubmit}
                disabled={!this.state.enableSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  renderContractorModal = () => (
    <Modal
      open={this.props.showContractorModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`search-contractor-modal ${this.props.modalContractorType}`} sx={webStyles.modalContainer}>
        {this.props.modalContractorType === "crediCard" && this.renderCreditCardForm()}
        {this.props.modalContractorType === "loader" && this.rederModalLoader()}
        {this.props.modalContractorType === "contractor" && this.renderContractors()}
        {this.props.modalContractorType === "rating" && this.renderRatingContractor()}
        {this.props.modalContractorType === "loader" && <Box>
          <Button
            data-test-id="btnCloseModal"
            variant="text"
            onClick={this.props.closeModal}
            className="search-contractor-modal-close"
          >
            X
          </Button>
        </Box>}
      </Box>
    </Modal>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          {this.renderContractorModal()}
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 8,
    boxShadow: 24,
    // gap: 10,   
  },
  modalButtonView: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10
  },
  progressContainer: {
    width: "100%",
    bgcolor: "ccc"
  },
  progressBar: {
    bgcolor: "#2a67ff",
    height: 10,
    borderRadius: 20
  }

};
const SiTText = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.005em",
  textAlign: "center",
  color: "#1E293B"

})

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
    padding:"10px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    position:"relative",
    zIndex:15,
    "&::before":{
      content:"''",
      height:"10px",
      width:"10px",
      backgroundColor:"#fff",
    position:"absolute",
      top:-5,
      left:"48%",
      transform: "rotate(135deg)",
      zIndex:5,
    }
  },
}))(Tooltip);


const RateText =styled(Typography)({
color: "#2966FF",
textAlign: "center",
fontFamily: "Lato",
fontSize: "24px",
fontStyle: "normal",
fontWeight: 700,
lineHeight: "28px", 
})

const RateTextLine =styled(Typography)({
  color: "#64748B",
  textAlign: "center",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "24px",
  })





// Customizable Area End
