// Customizable Area Start
import React, { useEffect, useState } from "react";
import { useMeeting, createCameraVideoTrack } from "@videosdk.live/react-sdk";
import MeetingView from "./MeetingView";

const Meeting = ({ onMeetingLeave, updateContracterIsJoined }) => {
  const [participantViewVisible] = useState(true);

  const onParticipantJoined = (participant) => {
    updateContracterIsJoined(participant);  
    window.alert("Default audio and video off you can turn on by clicking them.");   
    startRecording();
    console.log(" onParticipantJoined", participant);
  }
  const onParticipantLeft = (participant) => {
    end();
    confirmLeave();
    console.log(" onParticipantLeft", participant);
  }
  const onSpeakerChanged = (activeSpeakerId) => {
    console.log(" onSpeakerChanged", activeSpeakerId);
  };
  const onPresenterChanged = (presenterId) => {
    console.log(" onPresenterChanged", presenterId);
  }
  const onMainParticipantChanged = (participant) => {
    console.log(" onMainParticipantChanged", participant);
  }
  const onEntryRequested = (participantId, name) => {
    console.log(" onEntryRequested", participantId, name);
  }
  const onEntryResponded = (participantId, name) => {
    console.log(" onEntryResponded", participantId, name);
  }
  const onRecordingStarted = () => {
    console.log(" onRecordingStarted");
  }
  const onRecordingStopped = () => {
    console.log("onRecordingStopped");
    leave();
  }
  
  const onChatMessage = (data) => {
    console.log(" onChatMessage", data);
  }
  const onMeetingJoined = () => {
    console.log("onMeetingJoined");
  }
  const onMeetingLeft = () => {
    console.log("onMeetingLeft");
    confirmLeave();
    
  }
  const onLiveStreamStarted = (data) => {
    console.log("onLiveStreamStarted example", data);
  };
  const onLiveStreamStopped = (data) => {
    console.log("onLiveStreamStopped example", data);
  };

  const onVideoStateChanged = (data) => {
    console.log("onVideoStateChanged", data);
  };
  const onVideoSeeked = (data) => {
    console.log("onVideoSeeked", data);
  };

  const onWebcamRequested = (data) => {
    console.log("onWebcamRequested", data);
  };
  const onMicRequested = (data) => {
    console.log("onMicRequested", data);
  };
  const onPinStateChanged = (data) => {
    console.log("onPinStateChanged", data);
  };
  const onSwitchMeeting = (data) => {
    window.focus();
  };

  const onConnectionOpen = (data) => {
    console.log("onConnectionOpen", data);
  };

  const {
    meetingId,
    localParticipant,
    activeSpeakerId,
    participants,
    localMicOn,
    localWebcamOn,
    localScreenShareOn,
    isRecording,
    //
    leave,
    end,
    //
    startRecording,
    stopRecording,
    //
    //
    muteMic,
    unmuteMic,
    toggleMic,
    //
    disableWebcam,
    enableWebcam,
    toggleWebcam,
    //
    disableScreenShare,
    enableScreenShare,
    toggleScreenShare,
    //
    getMics,
    getWebcams,
    changeWebcam,
    changeMic,
  } = useMeeting({
    onParticipantJoined,
    onParticipantLeft,
    onSpeakerChanged,
    onPresenterChanged,
    onMainParticipantChanged,
    onEntryRequested,
    onEntryResponded,
    onRecordingStarted,
    onRecordingStopped,
    onChatMessage,
    onMeetingJoined,
    onMeetingLeft,
    onLiveStreamStarted,
    onLiveStreamStopped,
    onVideoStateChanged,
    onVideoSeeked,
    onWebcamRequested,
    onMicRequested,
    onPinStateChanged,
    onSwitchMeeting,
    onConnectionOpen,
  });
  const [value, setValue] = React.useState(0);

  const checkScreenSharing= (participants) => {
    const result = participants.map(participant => {
      const streams = participant[1].streams;
      const streamtentries = Array.from(streams.entries());
      const findShareStream =  streamtentries.filter(item => {
        const itemData = item[1];
        return itemData.kind === "share";
      });
      console.log(streamtentries,findShareStream,"streamtentries")

      return  findShareStream.length > 0;
    });
    
    return result;
  }
  const entriesArray = Array.from(participants.entries());
  const checkScreenshare = checkScreenSharing(entriesArray);
  const screenisAlreadySharing = checkScreenshare.find((val) => val === true)
   

  const handleStartRecording = () => {
    startRecording();
  };
  const handleStopRecording = () => {
    stopRecording();
  };
  const confirmLeave = () => {
    try {
      console.log("leave start-end func", participants.size);
      disableWebcam(); 
      muteMic();
      disableScreenShare();
      if(participants.size <=1){        
        onMeetingLeave("disableMeeting");
        end();
        leave();                
      }else{         
        onMeetingLeave("stoprecording"); 
        leave();       
      }
      console.log("leave end-")

    } catch(e) {
      console.log("error-",e)
    }
  };

  const getCustomWebCamCustomTrack = async (deviceID) => {
    const customCamTrack = await createCameraVideoTrack({
      cameraId: deviceID,      
      optimizationMode: "motion",
      facingMode: 'environment',
      encoderConfig: "h1080p_w1920p"      
    });
    return customCamTrack;
  }

  const viewProps = {
    participantViewVisible,
    meetingId,
    toggleMic,
    toggleWebcam,
    toggleScreenShare,
    value,
    setValue,
    handleStartRecording,
    handleStopRecording,
    confirmLeave,
    localMicOn,
    localWebcamOn,
    localScreenShareOn,
    disableWebcam,
    enableWebcam,
    muteMic,
    unmuteMic,
    disableScreenShare,
    enableScreenShare,
    getMics,
    getWebcams,
    localParticipant,
    activeSpeakerId,
    participants,
    isRecording,
    changeMic,
    screenisAlreadySharing,
    changeWebcam,
    getCustomWebCamCustomTrack
  };

  useEffect(() => {
    // Start the timer when the component mounts
    
    let timerId = setTimeout(() => {
      // Redirect to the home page after 2 minutes if contractor doesn't join
      confirmLeave();
      window.alert("Sorry! something went wrong");      
    }, 15000); //  in milliseconds
    if(participants.size > 1){   
      clearTimeout(timerId)           
    }
    // Save the timer ID in state
    // Clean up the timer if the contractor joins before the timeout
    return () => clearTimeout(timerId);
  }, [participants.size, ""]);   
  return <MeetingView testID="MeetingView" {...viewProps} />;
}

export default Meeting;
// Customizable Area End
